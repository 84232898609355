import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule as MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressBarModule as MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule as MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule as MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatAutocompleteModule as MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule as MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule as MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule as MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule as MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule as MatRadioModule } from '@angular/material/radio';
import { MatSnackBarModule as MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule as MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule as MatTooltipModule } from '@angular/material/tooltip';
import { NgxPayPalModule } from 'ngx-paypal';
import { NgxStripeModule } from 'ngx-stripe';
import { MakePaymentComponent } from './make-payment/make-payment.component';




@NgModule({
  declarations: [MakePaymentComponent],
  imports: [
    CommonModule,
    NgxPayPalModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatSelectModule,
    MatIconModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatTabsModule,
    MatSnackBarModule,
    MatFormFieldModule,
    MatProgressBarModule,
    //NgxStripeModule.forRoot('pk_test_OPp6oflL8Q5PQ3tuTU8W9u2s00mv7dTSG2'),
    NgxStripeModule.forRoot('pk_live_BkjRG8tI0MrHCn0rb9BhFiR500uTfKFPZs'),
    MatSlideToggleModule,
  ]
})
export class SharedPaymentModule { }
