<h1 class="custom-dialog-title" mat-dialog-title>
  <ng-container *ngIf="type == 'Autopilot'">
    <ng-container *ngIf="requestId != null; else allTriggers">
      Translate  <span class="primary-color">{{requestName}}</span>
    </ng-container>

    <ng-template #allTriggers>
      Translate all triggers
    </ng-template>
  </ng-container>

  <ng-container *ngIf="type == 'ApplicationPhone'">
    Translate application phone
  </ng-container>
</h1>

<div mat-dialog-content class="p-0">
  <div>
    <ng-container *ngIf="type == 'Autopilot'">
      From the default language <b>{{defaultLanguage}}</b> to all selected languages:
    </ng-container>

    <ng-container *ngIf="type == 'ApplicationPhone'">
      From the default language to all selected languages:
    </ng-container>

    <span *ngFor="let item of languageNames; let last = last">
      <b>{{item}}</b><span *ngIf="!last">,&nbsp;</span>
    </span>
  </div>

  <span class="mb-1 text-warning-color">
    <span class="small">
      <span>
        <span class="lni-warning"></span>
        All manual and automatic translations will be overwritten.
        We use automation tools for translation, so please verify the accuracy once completed.
      </span>
    </span>
  </span>
</div>

<div mat-dialog-actions>
  <button mat-button (click)="cancel()">Cancel</button>

  <button [disabled]="loading" mat-raised-button cdkFocusInitial color="accent" (click)="translate()"> Translate </button>
</div>
