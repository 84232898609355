import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiBaseUrl } from '../../constants/common.constants';
import { IAutomaticTextTranslation } from '../../interfaces/ai/automatic-text-translation';
import { ITranslateTextRequest } from '../../interfaces/ai/translate-text-request';
import { ITranslationResponse } from '../../interfaces/ai/translation-response';
import { IActivityRequest } from '../../interfaces/common/activity-request.interface';
import { DataSourceResponse } from '../../interfaces/data/datasourceresponse.interface';
import { GetStructResponse } from '../../interfaces/data/get-struct-response.interface';
import { IApplicationPhone } from '../../interfaces/voice/application-phone';
import { IJsonDesignerBehaviorRules } from '../../interfaces/voice/json-designer-behavior-rule';

@Injectable()
export class ApplicationPhoneService {

  private _baseUrl: string = `${ApiBaseUrl}applicationphone`;

  constructor(private httpClient: HttpClient) { }

  getApplicationsPhone(filter: string, type:string): Observable<IApplicationPhone[]> {
    return this.httpClient.get<IApplicationPhone[]>(`${this._baseUrl}/all/${type}/${filter}`);
  }

  getApplicationsPhoneByCustomerCode(customerCode: string, filter: string, type: string): Observable<IApplicationPhone[]> {
    return this.httpClient.get<IApplicationPhone[]>(`${this._baseUrl}/applications/${customerCode}/${type}/${filter}`);
  }

  getApplicationPhoneById(applicationId): Observable<IApplicationPhone> {
    return this.httpClient.get<IApplicationPhone>(`${this._baseUrl}/${applicationId}`);
  }

  createApplicationPhone(applicationDto: IApplicationPhone): Observable<GetStructResponse<boolean>> {
    return this.httpClient.post<GetStructResponse<boolean>>(`${this._baseUrl}/create`, applicationDto);
  }

  validateJsonText(jsonText: string): Observable<boolean> {
    const params = new HttpParams().set("jsonText", jsonText)
    return this.httpClient.post<boolean>(`${this._baseUrl}/validate`, params);
  }

  verifiUniqueIdentifier(uniqueIdentifier: string, applicationId: string, customerCode: string = ''): Observable<boolean> {
    const params = new HttpParams()
      .set("uniqueIdentifier", uniqueIdentifier)
      .set("applicationId", applicationId)
      .set("code", customerCode);

    return this.httpClient.post<boolean>(`${this._baseUrl}/identifier`, params);
  }

  getJsonDesignerBehaviorRules(): Observable<IJsonDesignerBehaviorRules[]> {
    return this.httpClient.get<IJsonDesignerBehaviorRules[]>(`${this._baseUrl}/rules`);
  }

  deleteApplicationPhone(applicationId): Observable<boolean> {
    return this.httpClient.get<boolean>(`${this._baseUrl}/delete/${applicationId}`);
  }

  getApplicationsPhoneForAdmin(customerCode: string): Observable<IApplicationPhone[]> {
    return this.httpClient.get<IApplicationPhone[]>(`${this._baseUrl}/admin-all/${customerCode}`);
  }

  getVoiceIds(languageCode: string) {
    return this.httpClient.get<string[]>(`${this._baseUrl}/voice/${languageCode}`);
  }

  translateApplicationPhone(applicationId: any): Observable<boolean> {
    return this.httpClient.get<boolean>(`${this._baseUrl}/translate/${applicationId}`);
  }

  translate(request: ITranslateTextRequest): Observable<GetStructResponse<ITranslationResponse>> {
    return this.httpClient.post<GetStructResponse<ITranslationResponse>>(`${this._baseUrl}/translate`, request);
  }

  getTranslationBilling(request: IActivityRequest, ownTransactions: boolean): Observable<DataSourceResponse<IAutomaticTextTranslation>> {
    return this.httpClient.post<DataSourceResponse<IAutomaticTextTranslation>>(`${this._baseUrl}/translation-billing/${ownTransactions}`, request);
  }

  getTimeSettings(): Observable<number> {
    return this.httpClient.get<number>(`${this._baseUrl}/time`);
  }

}
