import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiBaseUrl } from '../../constants/common.constants';
import { DataSourceResponse } from '../../interfaces/data/datasourceresponse.interface';
import { IMicroserviceInstance } from '../../interfaces/monitoring/microservice-instace';
import { IMicroserviceInstaceRequest } from '../../interfaces/monitoring/microservice-instace-request';
import { IMicroserviceLog } from '../../interfaces/monitoring/microservice-log';

@Injectable({
  providedIn: 'root'
})
export class MonitoringService {

  private _baseUrl: string = `${ApiBaseUrl}monitoring`;

  constructor(private httpClient: HttpClient) { }

  getMicroserviceInstances(request: IMicroserviceInstaceRequest): Observable<DataSourceResponse<IMicroserviceInstance>> {
    return this.httpClient.post<DataSourceResponse<IMicroserviceInstance>>(`${this._baseUrl}`, request);
  }

  getAllMicroserviceInstances(request: IMicroserviceInstaceRequest): Observable<DataSourceResponse<IMicroserviceInstance>> {
    return this.httpClient.post<DataSourceResponse<IMicroserviceInstance>>(`${this._baseUrl}/all`, request);
  }

  getMicroserviceLogs(request: IMicroserviceInstaceRequest): Observable<DataSourceResponse<IMicroserviceLog>> {
    return this.httpClient.post<DataSourceResponse<IMicroserviceLog>>(`${this._baseUrl}/logs`, request);
  }

  getStasisAppNames(): Observable<string[]> {
    return this.httpClient.get<string[]>(`${this._baseUrl}`);
  }
}
