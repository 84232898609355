<h1 class="custom-dialog-title" mat-dialog-title>
  Translation from {{ element.fromLanguage }}
</h1>

<div mat-dialog-content>
  <div class="custom-dark">Initial text: {{ element.text }}</div>

  <div class="full-width row mt-2">
    <div class="col-sm-4">
      <b class="custom-dark">Number of intervals used: {{ element.numberOfIntervals }}</b>
      <div class="small-text">*An interval contains {{element.interval}} characters</div>
    </div>

    <div class="col-sm-4">
      <b class="custom-dark">Price per interval: {{ element.priceInterval | number: '1.3-5' }} €</b>
      <div class="small-text">*The price for an interval</div>
    </div>

    <div class="col-sm-4">
      <b class="custom-dark">Number of requests: {{ element.toLanguages.length }}</b>
      <div class="small-text">*Because it was translated into {{ element.toLanguages.length }} languages</div>
    </div>
  </div>

  <div class="full-width mt-2">
    <b class="custom-dark">Total price: {{ element.price | number: '1.3-5' }} €</b>
    <div class="small-text">*Total price = Number of intervals * Price per interval * Number of requests</div>
  </div>

  <div class="full-width row mt-2">
    <div class="col-sm-4">
      <span class="custom-label">Language:</span>
    </div>

    <div class="col-sm-8">
      <span class="custom-label">Text:</span>
    </div>
  </div>

  <div class="full-width row mt-2" *ngFor="let item of element.translatedTexts | keyvalue">
    <div class="col-sm-4 custom-dark">
      {{ getLanguageName(item.key) }}
    </div>

    <div class="col-sm-8 custom-dark">
      {{ item.value }}
    </div>

    <div class="col-sm-12">
      <mat-divider class="mt-2"></mat-divider>
    </div>
  </div>

</div>



