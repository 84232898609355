<div>
  <form [formGroup]="form" [hidden]="success || paymentInstructions != null">
    <mat-form-field class="search-field full-width">
      <span matPrefix><i class="lni-euro"></i></span>
      <input type="text" matInput [formControl]="amount"
             placeholder="Recharge amount"
             autocomplete="off" [hidden]="success"
             #tooltip="matTooltip"
             matTooltip="Minimum recharge {{minAmount}}€"
             (keydown)="tooltip.show()" />
      <mat-error *ngIf="amount.invalid && amount.touched">
        The amount must be higher than {{minAmount}}€.
      </mat-error>
    </mat-form-field>

    <div *ngIf="appliedDiscount!=null" class="current-discount">
      <div *ngIf="appliedDiscount.possibleDiscount>0">
        For this amount, you will receive {{appliedDiscount.possibleDiscount}}% discount until {{appliedDiscount.nextDiscountExpiration | date: 'dd/MM/yyyy' }}
        on all price lists
      </div>
      <div *ngIf="appliedDiscount.nextDiscount>0" class="next-discount">
        Add {{appliedDiscount.minAmountForNextDiscount | number :'1.2-2'}}€  and get {{appliedDiscount.nextDiscount}}% discount

        <span appliedDiscount.possibleDiscount=0>
          until {{appliedDiscount.nextDiscountExpiration | date: 'dd/MM/yyyy' }} on all price lists
        </span>
      </div>
    </div>

    <div *ngIf="calculateVAT">
      <div class="mb-2 mt-3">
        <input type="text" class="w-30" [formControl]="valueVAT" autocomplete="off" [readonly]="true" /> &nbsp;&nbsp;
        <label> VAT({{vat}}%)</label>
      </div>

      <div class="mb-4">
        <input type="text" class="w-30" [formControl]="totalAmount" autocomplete="off" [readonly]="true" />&nbsp;&nbsp;
        <label> Total amount due</label>
      </div>
    </div>

    <div *ngIf="validAmount">
      <mat-radio-group aria-label="Payment Method" [hidden]="paymentType.value=='paypal'" [formControl]="paymentType">
        <mat-radio-button value="card">Card</mat-radio-button>
        <mat-radio-button value="paypal">PayPal</mat-radio-button>
        <mat-radio-button value="bank-transfer">Bank transfer</mat-radio-button>
      </mat-radio-group>

      <ngx-paypal [hidden]="loading" [config]="payPalConfig"></ngx-paypal>


      <mat-form-field class="full-width" *ngIf="isCard" [hidden]="hideDropdown">
        <mat-label> Select card </mat-label>
        <mat-select [formControl]="paymentMethod">
          <mat-option *ngFor="let element of paymentMethodsList" [value]="element.id">
            ************{{element.last4}}
          </mat-option>
          <mat-option value="0">Pay with a new card</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!--<div class="mb-4">
    <mat-form-field class="full-width">
      <input type="text" matInput placeholder="Description" [formControl]="description" />
    </mat-form-field>
  </div>-->
    <!--stripe package-->
    <div [hidden]="validAmount==false || success ||  paymentMethod.value !='0' || paymentType.value!='card'">
      <div class="row">
        <div class="col-sm-6">
          <label for="card-element">Enter card info:</label>
          <ngx-stripe-card [options]="cardOptions"
                           [elementsOptions]="elementsOptions">

          </ngx-stripe-card>
        </div>
        <div class="col-sm-6">
          <mat-form-field class="search-field full-width">
            <input type="text" matInput formControlName="name"
                   placeholder="Card Holder Name"
                   autocomplete="off" />
            <mat-error *ngIf="name.invalid && name.touched">
              The name is required
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-12">
          <mat-form-field class="search-field full-width">
            <input type="text" matInput formControlName="address"
                   placeholder="Card Holder Address"
                   autocomplete="off" />
            <mat-error *ngIf="address.invalid && address.touched">
              The address is required
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-6">
          <mat-form-field class="full-width">
            <input type="text" placeholder="Country" matInput formControlName="country" [matAutocomplete]="auto">
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
              <mat-option *ngFor="let country of filteredOptions | async" [value]="country.name">
                <!-- for localhost and publish without subdomain-->
                <img src="../assets/img/countries/{{country.languageCode}}.png" class="img-flag" alt="img" /> &nbsp;{{country.name}}

                <!-- for publish on an subdomain-->
                <!--<img src="/{{root}}/assets/img/countries/{{country.languageCode}}.png" class="img-flag" alt="img" /> &nbsp;{{country.name}}-->
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="country.invalid && country.touched && country.hasError('forbiddenCountries')">
              The country is invalid.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-6">
          <mat-form-field class="full-width">
            <input type="text" matInput placeholder="Postal Code" formControlName="postalCode" />
            <mat-error *ngIf="postalCode.invalid && postalCode.touched">
              The postal code is required.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-6">
          <mat-form-field class="search-field full-width">
            <input type="text" matInput formControlName="state"
                   placeholder="State / Region"
                   autocomplete="off" />
            <mat-error *ngIf="state.invalid && state.touched">
              The state is required
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-6">
          <mat-form-field class="search-field full-width">
            <input type="text" matInput formControlName="city"
                   placeholder="City"
                   autocomplete="off" />
            <mat-error *ngIf="city.invalid && city.touched">
              The city is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="full-width text-left" >
      <mat-checkbox formControlName="acceptTerms" color="accent">Accept our <a href="https://www.dillo.cloud/it/condizioni-contrattuali/" target="_blank">Terms and Conditions</a></mat-checkbox>
    </div>
    <div class="mg-top1rem" *ngIf="paymentInstructions == null">
      <button mat-raised-button
              (click)="cancel()"
              [disabled]="loading" type="button">
        Cancel
      </button>
      &nbsp;&nbsp;
      <button mat-raised-button
              cdkFocusInitial
              color="accent"
              (click)="pay()"
              [disabled]="loading || form.invalid || this.acceptTerms.value==false">
        <span *ngIf="isCard==true">Recharge</span>
        <span *ngIf="isCard==false">Recharge by Bank Transfer </span>
      </button>
    </div>
    <small class="gray-color" *ngIf="paymentType.value=='bank-transfer'">Information regarding the bank transfer will be displayed after you click 'Recharge'.  Credits will be added after the founds are arrived in Dillo Account.</small>
  </form>
    <!--<form id="payment-form" [hidden]="success || paymentMethod.value !='0'">
      <div class="form-row" [hidden]="validAmount==false">
        <label for="card-element">Enter card info:</label>
        <div id="card-element"></div>
        <div id="card-errors" role="alert"></div>
      </div>
      <div class="mg-top1rem">
        <button mat-raised-button
                (click)="cancel()"
                [disabled]="loading" type="button">
          Cancel
        </button>
        &nbsp;&nbsp;
        <button mat-raised-button
                cdkFocusInitial
                color="accent"
                [disabled]="loading || form.invalid">
          Recharge
        </button>
      </div>
    </form>


  <div  mat-dialog-actions *ngIf="paymentMethod.value !='0' && success==false">
    <button mat-raised-button
            (click)="cancel()"
            [disabled]="loading">Cancel</button>
    <button mat-raised-button
            [hidden]="!isCard"
            cdkFocusInitial
            color="accent"
            (click)="pay(null)"
            [disabled]="loading || form.invalid"> Recharge
    </button>
  </div>-->
</div>

<div *ngIf="paymentType.value=='bank-transfer' && paymentInstructions !=null">
  <h4>Bank information</h4>
  <p>Transfer funds using the following bank information:</p>

  <div class="d-flex justify-content-between w-70">
    <div>
      <div class="gray-color">IBAN </div>
      <div class="gray-color">BIC</div>
      <div class="gray-color">Account holder name: </div>
      <div class="gray-color">Reference: </div>
      <div class="gray-color" *ngIf="calculateVAT">Recharge amount: </div>
      <div class="gray-color" *ngIf="calculateVAT">VAT: </div>
      <div class="gray-color">Transfer amount: </div>
    </div>
    <div>
      <div>{{paymentInstructions.iban}}</div>
      <div>{{paymentInstructions.bic}}</div>
      <div>{{paymentInstructions.companyName}}</div>
      <div>{{paymentInstructions.reference}}</div>
      <div *ngIf="calculateVAT">{{amount.value}}€ </div>
      <div *ngIf="calculateVAT">{{vat}}% </div>
      <div>{{totalAmount.value}}€ </div>
    </div>
  </div>
  <p class="mt-3">If you can, please include the reference mentioned above when you send your bank transfer.</p>
  <p>We also sent this information to the email: {{billingInfo.email}}.</p>
</div>

  <div class="mt-4" [hidden]="!loading">
    <div class="progress-spinner-container">
      <div class="progress-spinner-content">
        <mat-progress-spinner mode="indeterminate" color="primary" diameter="75"></mat-progress-spinner>
      </div>
    </div>
  </div>


  <div class="mg-top1rem" *ngIf="confirmation !=null">
    <span *ngIf="success" class="confirmation-msg"> {{confirmation}} </span>
    <span *ngIf="!success" mat-error> {{confirmation}} </span>
   
  </div>

  <div *ngIf="success && paymentMethod.value =='0'">
    <div class="full-width text-left">
      <mat-checkbox (change)="saveCard($event)" color="accent" [disabled]="cardSaved">Save this card for future payments</mat-checkbox>
    </div>

    <span *ngIf="cardSaved">
      Card info was sucesfully saved!
    </span>

    <span *ngIf="cardSaved==false">
      An error occurred while saving card info!
    </span>

  </div>

  <button mat-raised-button
          (click)="cancel()"
          [disabled]="loading"
          *ngIf="success || paymentInstructions!=null"
          class="mg-top1rem">
    Close
  </button>
 

