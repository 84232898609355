import { UserRoles } from "./api-authorization/api-authorization.constants";

declare global {
  interface String {
    isAdmin(): boolean;
  }
}

Object.defineProperty(String.prototype, "isAdmin", {
  value(this: string): boolean {
    const role = this.toLowerCase();
    return role === UserRoles.SuperAdmin.toLowerCase() || role === UserRoles.Admin.toLowerCase();
  },
  writable: true,
  configurable: true,
});

export { };
