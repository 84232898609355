import { Component, OnInit } from '@angular/core';
import { AuthorizeService, IUser } from '../authorize.service';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UserRoles } from '../api-authorization.constants';
import { CommonModules } from '../../app/management/core/constants/common.constants';
import { LocalStorageService } from '../../app/management/core/services/common/local-storage.service';
import { LiveAgentStatitsticsService } from '../../app/management/core/services/live-agent/live-agent-statistics.service';
import { MatSnackBar as MatSnackBar } from '@angular/material/snack-bar';
import { LiveAgentEntryLocalStorageKeys } from '../../app/management/core/constants/live-agent-interaction-types';
import { LiveAgentActivityLogTypes } from '../../app/management/core/constants/live-agent-actvity-log-types';
import { LiveAgentService } from '../../app/management/core/services/live-agent/live-agent.service';

@Component({
  selector: 'app-login-menu',
  templateUrl: './login-menu.component.html',
  styleUrls: ['./login-menu.component.scss'],
  providers: [LiveAgentStatitsticsService, LiveAgentService]
})
export class LoginMenuComponent implements OnInit {
  public isAuthenticated: Observable<boolean>;
  public isAdmin: boolean=false;
  public userRole: string;
  public user: IUser = null;

  constructor(private authorizeService: AuthorizeService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private statisticsService: LiveAgentStatitsticsService,
    private liveAgentService: LiveAgentService,
    private snackBar: MatSnackBar  ) { }

  ngOnInit() {
    this.isAuthenticated = this.authorizeService.isAuthenticated();
    this.authorizeService.getUser().subscribe(u => this.user = u);
    this.authorizeService.getUserRole().subscribe(y => {
      this.userRole = y.toLowerCase();
      if (y.isAdmin()) {
        this.userRole = 'admin';    
        this.isAdmin = true;
      }

      if (y == UserRoles.LiveAgent) {
        this.userRole = 'agent';
      }

    });
  }

  openSettings() {
    this.router.navigate([`management/${this.userRole}/settings`]);
  }
  openBilling() {
    this.router.navigate([`management/${this.userRole}/${CommonModules.Billing}`]);
  }

  //logout() {
  //  this.authorizeService.completeSignOut("/authentication/login");
  //}

  logout() {
    if (this.userRole != "agent") {
     // this.authorizeService.completeSignOut("/authentication/login");

      this.router.navigate(["/authentication/logout"], { state: { local: true } });
    }
    else {
      //check first if it has active sessions
      this.statisticsService.hasActiveInteractions().subscribe(result => {

        if (result == true) {
          this.snackBar.open("Please end all your active tasks before logout.", "Ok", {
            duration: 5000,
          });
        }
        else {
          //remove keys
          this.localStorageService.remove(LiveAgentEntryLocalStorageKeys.Team);
          this.localStorageService.remove(LiveAgentEntryLocalStorageKeys.Task);

          //insert first log
          this.liveAgentService.insertActivityLog(LiveAgentActivityLogTypes.Logout, "").subscribe(x => {
            if (x == true) {
              this.router.navigate(["/authentication/logout"], { state: { local: true } });
            }
            else {
              this.snackBar.open("There was an error.Please try again later", "Ok", {
                duration: 3000,
              });
            }
          });
        }

      });

    }

  }
}
