import { Injectable } from '@angular/core';
import { ManageAccountService } from './manage-account.service';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor(private manageAccountService: ManageAccountService) { }

  private changes: Subject<string> = new Subject<string>();

  onChange(): Observable<string> {
    return this.changes.asObservable();
  }

  set(key, value) {
    localStorage.setItem(key, value);
    this.changes.next(key);
  }

  get(key) {
    return localStorage.getItem(key);
  }

  remove(key) {
    localStorage.removeItem(key);
    this.changes.next(key);
  }

  setWithExpiry(key, value, ttl) {
    var now = new Date();

    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
      value: value,
      expiry: now.getTime() + (ttl * 60 * 60 * 1000),
    }
    //console.log("expiry=", item.expiry);
    localStorage.setItem(key, JSON.stringify(item));

    this.changes.next(key);
  }

  getWithExpiry(key) {
    const itemStr = localStorage.getItem(key);
    // if the item doesn't exist, return null
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);

    const now = new Date();
    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      localStorage.removeItem(key);
      
      return this.getFromAppSettings();
    }
    return item.value;
  }

  getFromAppSettings(): string[] {
    var modules: string[] = [];
    this.manageAccountService.getAppSettings().subscribe(value => {
      if (value == null) {
        return;
      }

      this.setWithExpiry("BetaModules", value, 1);
      modules = value;
      return modules;
    });
    return modules;
  }
}
