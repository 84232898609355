import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-management-layout',
  templateUrl: './management-layout.component.html',
  styleUrls: ['./management-layout.component.scss']
})
export class ManagementLayoutComponent implements OnInit {

    constructor() { }

    //constructor(private authorizeService: AuthorizeService,
    //    private router: Router) {

    //    this.authorizeService.getUserRole().subscribe(x => {

    //        if (x == null || x == "") {
    //            this.router.navigate([ApplicationPaths.Login]);
    //        }
    //        else {
    //            this.authorizeService.getUserRole().subscribe(y => {
    //                console.log("ngOninit");
    //                if (y == UserRoles.SuperAdmin) {
    //                    y = 'admin'
    //                    this.router.navigate([`management/${y.toLowerCase()}/console/settings`]);
    //                    return;
    //                }
    //                this.router.navigate([`management/${y.toLowerCase()}/console`]);
    //            });
    //        }
    //    });

    //}


  ngOnInit() {
  }

}
