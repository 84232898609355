import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-presentation-layout',
  templateUrl: './presentation-layout.component.html',
  styleUrls: ['./presentation-layout.component.scss']
})
export class PresentationLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    
  }

}
