import { DragDropModule } from '@angular/cdk/drag-drop';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { CommonModule, DatePipe } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { QRCodeModule } from 'angularx-qrcode';
import { NgChartsModule } from 'ng2-charts';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { NgxPayPalModule } from 'ngx-paypal';
import { NgxStripeModule } from 'ngx-stripe';
import { PipeModule } from '../pipe/pipe.module';
import { RequestStatusCallbackRequestsComponent } from './components/activity-history/request-status-callback-requests/request-status-callback-requests.component';
import { SendActionMessageComponent } from './components/activity-history/send-action-message/send-action-message.component';
import { StorageDetailsComponent } from './components/activity-history/storage-details/storage-details.component';
import { CallPriceDetailsComponent } from './components/activity-history/voice-activity/call-price-details/call-price-details.component';
import { DialActionInfoComponent } from './components/activity-history/voice-activity/dial-action-info/dial-action-info.component';
import { PreviewInboundChannelBehaviorComponent } from './components/activity-history/voice-activity/preview-inbound-channel-behavior/preview-inbound-channel-behavior.component';
import { RecordingListComponent } from './components/activity-history/voice-activity/recording-list/recording-list.component';
import { ViewMenuDetectedIntentsComponent } from './components/activity-history/voice-activity/view-menu-detected-intents/view-menu-detected-intents.component';
import { VoiceActivityLogsComponent } from './components/activity-history/voice-activity/voice-activity-logs/voice-activity-logs.component';
import { RequestAgentOfferComponent } from './components/agent/request-agent-offer/request-agent-offer.component';
import { BotIntentListComponent } from './components/ai/bot-intent-list/bot-intent-list.component';
import { CommonBotCardComponent } from './components/ai/common-bot-card/common-bot-card.component';
import { PublishBotDialogComponent } from './components/ai/publish-bot-dialog/publish-bot-dialog.component';
import { CreditLimitReachedComponent } from './components/billing/credit-limit-reached/credit-limit-reached.component';
import { EditAutoRechargeInfoComponent } from './components/billing/edit-auto-recharge-info/edit-auto-recharge-info.component';
import { EditPaymentMethodComponent } from './components/billing/manage-payment-methods/edit-payment-method/edit-payment-method.component';
import { ManagePaymentMethodsComponent } from './components/billing/manage-payment-methods/manage-payment-methods.component';
import { SetDefaultPaymentMethodComponent } from './components/billing/manage-payment-methods/set-default-payment-method/set-default-payment-method.component';
import { NoCreditsAvailableComponent } from './components/billing/no-credits-available/no-credits-available.component';
import { PaymentHistoryComponent } from './components/billing/payment-history/payment-history.component';
import { RechargeComponent } from './components/billing/recharge/recharge.component';
import { BarChartComponent } from './components/charts/bar-chart/bar-chart.component';
import { CodeSamplesComponent } from './components/code-samples/code-samples.component';
import { CommonConfirmationComponent } from './components/common-confirmation/common-confirmation.component';
import { DeleteItemsConfirmationComponent } from './components/delete-items-confirmation/delete-items-confirmation.component';
import { FilterGridComponent } from './components/filter-grid/filter-grid.component';
import { InboundPriceDetailsComponent } from './components/inbound-price-details/inbound-price-details.component';
import { ViewInboundActiveNumberSettingsComponent } from './components/inbound/view-inbound-active-number-settings/view-inbound-active-number-settings.component';
import { ViewInboundBasePricelistItemsComponent } from './components/inbound/view-inbound-base-pricelist-items/view-inbound-base-pricelist-items.component';
import { MicroserviceInstacesComponent } from './components/monitoring/microservice-instaces/microservice-instaces.component';
import { PhoneViewerComponent } from './components/phone-viewer/phone-viewer.component';
import { RandomMessageComponent } from './components/random-message/random-message.component';
import { RequestTraceListComponent } from './components/request-trace-list/request-trace-list.component';
import { SetDefaultSenderComponent } from './components/set-default-sender/set-default-sender.component';
import { SlidePanelComponent } from './components/slide-panel/slide-panel.component';
import { SmsVoiceSettingDialogComponent } from './components/sms-voice-setting-dialog/sms-voice-setting-dialog.component';
import { TimePickerComponent } from './components/time-picker/time-picker.component';
import { ViewInboundApiRequestsComponent } from './components/view-inbound-api-requests/view-inbound-api-requests.component';
import { DetailedActivityComponent } from './components/whatsapp-activity/detailed-activity/detailed-activity.component';
import { GeneralActivityComponent } from './components/whatsapp-activity/general-activity/general-activity.component';
import { WhatsappActivityComponent } from './components/whatsapp-activity/whatsapp-activity.component';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    NgxPayPalModule,
    MatGridListModule,
    MatTableModule,
    MatPaginatorModule,
    MatCardModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatSelectModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    DragDropModule,
    MatStepperModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatTabsModule,
    NgJsonEditorModule,
    MatSnackBarModule,
    MatSortModule,
    RouterModule,
    QRCodeModule,
    MatListModule,
    MatDividerModule,
    MatFormFieldModule,
    NgChartsModule,
    MatProgressBarModule,
    //NgxStripeModule.forRoot('pk_test_OPp6oflL8Q5PQ3tuTU8W9u2s00mv7dTSG2'),
    NgxStripeModule.forRoot('pk_live_BkjRG8tI0MrHCn0rb9BhFiR500uTfKFPZs'),
    NgxMaterialTimepickerModule,
    MatSlideToggleModule,
    MonacoEditorModule,
    PipeModule,
    MatSidenavModule
  ],
  declarations: [
    SendActionMessageComponent,
    VoiceActivityLogsComponent,
    DeleteItemsConfirmationComponent,
    SetDefaultSenderComponent,
    ManagePaymentMethodsComponent,
    EditPaymentMethodComponent,
    SetDefaultPaymentMethodComponent,
    RechargeComponent,
    PaymentHistoryComponent,
    EditAutoRechargeInfoComponent,
    NoCreditsAvailableComponent,
    CreditLimitReachedComponent,
    ViewInboundBasePricelistItemsComponent,
    CommonConfirmationComponent,
    PreviewInboundChannelBehaviorComponent,
    DialActionInfoComponent,
    RecordingListComponent,
    
    RandomMessageComponent,
    SmsVoiceSettingDialogComponent,
    FilterGridComponent,
    SlidePanelComponent,
    RequestAgentOfferComponent,
    TimePickerComponent,
    MicroserviceInstacesComponent,
    BarChartComponent,
    CallPriceDetailsComponent,
    StorageDetailsComponent,
    ViewInboundApiRequestsComponent,
    BotIntentListComponent,
    InboundPriceDetailsComponent,
    ViewMenuDetectedIntentsComponent,
    CommonBotCardComponent,
    PublishBotDialogComponent,
    CodeSamplesComponent,
    RequestTraceListComponent,
    RequestStatusCallbackRequestsComponent,
    PhoneViewerComponent,
    ViewInboundActiveNumberSettingsComponent,
    WhatsappActivityComponent,
    GeneralActivityComponent,
    DetailedActivityComponent
  ],
  exports: [
    FilterGridComponent,
    SlidePanelComponent,
    CommonBotCardComponent,
    PublishBotDialogComponent,
    CodeSamplesComponent,
    PhoneViewerComponent,
    WhatsappActivityComponent,
    GeneralActivityComponent,
    DetailedActivityComponent,
    TimePickerComponent
  ],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false }

    },
    DatePipe
  ],
})
export class SharedModule { }
