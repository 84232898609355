import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AutopilotService } from '../../../../core/services/autopilot/autopilot.service';
import { ApplicationPhoneService } from '../../../../core/services/voice/application-phone.service';

@Component({
  selector: 'app-translate-all-info',
  templateUrl: './translate-all-info.component.html',
  styleUrl: './translate-all-info.component.scss',
  providers: [AutopilotService, ApplicationPhoneService]
})
export class TranslateAllInfoComponent implements OnInit {

  public type: TranslateType = TranslateType.None;

  //Common
  public requestId: any = null;
  public requestName: string = "";
  public languageNames: string[] = [];
  public loading: boolean = false;

  //Bots
  public defaultLanguage: string = "";
  public botId: any = '';

  constructor(private dialogRef: MatDialogRef<TranslateAllInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private autopilotService: AutopilotService,
    private applicationPhoneService: ApplicationPhoneService) {

    if (data == null) {
      return;
    }

    this.type = data.type;

    this.requestId = data.requestId;
    this.requestName = data.requestName;
    this.languageNames = data.languageNames;

    this.defaultLanguage = data.defaultLanguage;
    this.botId = data.botId;
  }

  ngOnInit() {

  }

  cancel() {
    this.dialogRef.close(null);
  }

  translate() {
    this.loading = true;

    if (this.type == TranslateType.Autopilot) {
      this.handleAutopilotTranslation();
    }

    if (this.type == TranslateType.ApplicationPhone) {
      this.handleApplicationPhoneTranslation();
    }
  }

  handleAutopilotTranslation() {
    const id = this.requestId != null ? this.requestId : '';

    this.autopilotService.translate(this.botId, id).subscribe(response => {
      this.dialogRef.close(response);
    });
  }

  handleApplicationPhoneTranslation() {
    this.applicationPhoneService.translateApplicationPhone(this.requestId).subscribe(response => {
      this.dialogRef.close(response);
    });
  }
}

export enum TranslateType {
  None = 'None',
  Autopilot = 'Autopilot',
  ApplicationPhone = 'ApplicationPhone'
}
