//export const ApiBaseUrl = "http://dillo-1471688698.eu-central-1.elb.amazonaws.com/ndoctwebapi/api/"; //amazon ec2 - external ip
//export const ApiBaseUrl = "http://172.31.37.29/NdoctWebApi/api/"; //amazon ec2 - internal ip
//export const ApiBaseUrl = "http://italiacall.sendservice.eu/ndoctwebapi/api/"; //italiacall
//export const ApiBaseUrl = "http://localhost:52181/api/"; //localhost
export const ApiBaseUrl = "/api/";
//export const DocsBaseUrl = "https://docs.dillo.cloud";
//export const DocsUrl = "https://localhost:44315";
//export const DocsUrl = "https://netdoctrine-dev-docs.azurewebsites.net";
export const DocsUrl = "https://docs.dillo.cloud";
//export const WebsiteBaseUrl = "https://netdoctrine-dev.azurewebsites.net/";
export const WebsiteBaseUrl = "https://console.dillo.cloud/";

export const CommonModules = {
  Console: 'console',
  Email: 'email',
  Sms: 'sms',
  Voice: 'voice',
  Users: 'users',
  Campaign: 'campaign',
  Settings: 'settings',
  Billing: 'billing',
  RatesPrice: 'rates-price',
  InboundVoice: 'inbound-voice',
  InboundEmail: 'inbound-email',
  DidNumber: 'did-number',
  Agents: 'agents',
  Marketing: 'marketing',
  ApplicationSms: 'application-sms',
  AI: 'ai',
  BotManagement: 'manage-bot',
  BotActivity: "bot-activity",
  AssistantManagement: "assistant-management",
  AssistantTesting: "assistant-testing",
  AssistantBilling: "assistant-billing",
  AssistantEndPointHits: "assistant-end-point-hits",
  AutopilotActivity: 'dialog-activity',
  Debugging: 'debugging',
  Whatsapp: "whatsapp",
  Package: "support-plans"
};

export const LiveAgentModules = {
  Console: 'console',
  Task: 'task',
  Team: 'team',
  Settings: 'settings'
}

export const CommonPages = {
  Providers: 'providers',
  Pricelists: 'pricelists',
  Credits: 'credits',
  ChangePassword: "change-password",
  Alerts: 'credit-alerts',
  BasePrices: 'prices',
  AccountDetails: 'account-details',
  ActiveNumber: '',
  BuyNewDid: 'new-did',
  ActivationRequest: 'activation-request',
  CancellationRequest: 'cancellation-request',
  DidBilling: 'did-billing',
  InboundEmailBilling: 'inbound-billing',
  TwoFactorAuthentication: 'two-factor-authentication',
  VoiceConference: "voice-conference",
  PbxTrunkList: "pbx-trunk-list",
  PbxTrunkManagement: "manage-pbx-trunk",
  AgentsList: '',
  AgentActivity: "activity",
  AgentGroup: "group",
  LiveAgentSession: "session",
  AgentGlobalSettings: "global-settings",
  AgentRequest: "request",
  AgentBilling: "agent-billing",
  AgentTask: "agent-task",
  VoiceCampaign: "voice-campaign",
  Bot: 'bot',
  BotEntity: "entity",
  BotPredictions: "predictions",
  BotPublishedVersions: "versions",
  BotTesting: "bot-testing",
  AIPriceList: "ai",
  AutopilotWebSettings: "chat-web-settings",
  AutopilotWhatsappSettings: "chat-whatsapp-settings",
  AutopilotDesign: "chat-web-design",
  AutopilotIntegration: "chat-integrations",
  AutopilotTriggers: "chat-trigger",
  LiveAgentDashboard: "live-agent-dashboard",
  WhatsappTemplates: "templates",
  WhatsappActivity: "",
  WhatsappPriceList: "whatsapp",
  PackageRequests: "support-plans-purchase",
  ActivePackage: "active-plans",
  PackageTradePolicy: "trade-policy",
  SupportPlans: "support-plans",
  Billing: "billing"
};

export const CustomerErrorCodes = {
  Username: 'UsernameExists',
  Email: 'EmailExists',
  CustomerCode: 'CustomerCodeExists'
};

export const AggregateType = {
  Sum: 'Sum',
  Average: 'Average',
  Total: 'Total'
}

export const SendActionType = {
  Voice: "Voice",
  Sms: "Sms",
  Email: "Email",
  Whatsapp: "Whatsapp"
}

export const ImageSrcType = {
  Default: 'default',
  Agents: "agents"

};

export const DefaultImageNames = {
  UploadImage: 'upload-photo.PNG',
  NoImage: "agent-no-profile.png"
}
