import { Injectable } from '@angular/core';
import { ApiBaseUrl } from '../../constants/common.constants';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IManageAccount } from '../../interfaces/common/manage-account.interface';
import { ITimeZone } from '../../interfaces/common/time-zone.interface';
import { IResponseCall } from '../../interfaces/common/response-call.interface';
import { IUser2faAuthCodes } from '../../interfaces/user/user2fa-auth-codes';

@Injectable()
export class ManageAccountService {

  private _baseUrl: string = `${ApiBaseUrl}manageaccount`;

  constructor(private httpClient: HttpClient) { }

  getAccountInfo(): Observable<IManageAccount> {
    return this.httpClient.get<IManageAccount>(`${this._baseUrl}`);
  }

  getTimeZones(): Observable<ITimeZone[]> {
    return this.httpClient.get<ITimeZone[]>(`${this._baseUrl}/timeZone`);
  }

  updateAccountOInfo(account: IManageAccount): Observable<IResponseCall> {
    return this.httpClient.post<IResponseCall>(`${this._baseUrl}`,account );
  }

  changePassword(oldPassword: string, newPassword: string): Observable<boolean> {
    const params = new HttpParams().set("oldPassword", oldPassword).set("newPassword", newPassword);
    return this.httpClient.post<boolean>(`${this._baseUrl}/changePassword`, params)
  }
  
  checkEnabled2faAuth(): Observable<boolean> {
    return this.httpClient.get<boolean>(`${this._baseUrl}/check2faAuth`);
  }

  getAtuhenticatorCodes(): Observable<IUser2faAuthCodes> {
    return this.httpClient.get<IUser2faAuthCodes>(`${this._baseUrl}/2faAuthCodes`);
  }

  enable2FaAuth(verificationCode: string): Observable<boolean> {
    const params = new HttpParams().set("code", verificationCode);
    return this.httpClient.post<boolean>(`${this._baseUrl}/2faAuth`, params);
  }

  disable2FaAuth(): Observable<boolean> {
    return this.httpClient.get<boolean>(`${this._baseUrl}/disable2faAuth`);
  }

  getAppSettings(): Observable<string[]> {
    return this.httpClient.get<string[]>(`${this._baseUrl}/appsettingsBeta`);
  }

  refreshAuthToken(): Observable<boolean> {
    return this.httpClient.get<boolean>(`${this._baseUrl}/refresh-token`);
  }

}
