import { Injectable } from '@angular/core';
import { ApiBaseUrl } from '../../constants/common.constants';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IRechargeDiscount } from '../../interfaces/payment/recharge-discount.interface';
import { Observable } from 'rxjs';
import { AppliedDiscountResponse } from '../../interfaces/payment/applied-discount-response.interface';

@Injectable()
export class RechargeDiscountService {

  private _baseUrl = `${ApiBaseUrl}rechargediscount`

  constructor(private httpClient: HttpClient) { }

  getRechargeDiscounts(): Observable<IRechargeDiscount[]> {
    return this.httpClient.get<IRechargeDiscount[]>(`${this._baseUrl}`);
  }

  getRechargeDiscount(id:any): Observable<IRechargeDiscount> {
    return this.httpClient.get<IRechargeDiscount>(`${this._baseUrl}/discount/${id}`);
  }

  updateRechargeDiscount(rechargeDiscount: IRechargeDiscount): Observable<number> {
    return this.httpClient.post<number>(`${this._baseUrl}/update/`, rechargeDiscount);
  }

  deleteRechargeDiscount(rechargeDiscount: IRechargeDiscount): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this._baseUrl}/delete/`, rechargeDiscount);
  }

  createRechargeDiscount(rechargeDiscount: IRechargeDiscount): Observable<number> {
    return this.httpClient.post<number>(`${this._baseUrl}/create/`, rechargeDiscount);
  }

  updateAllRechargeDiscount(monthToExpire: number): Observable<boolean> {
    const params = new HttpParams().set("monthToExpire", monthToExpire.toString());
    return this.httpClient.post<boolean>(`${this._baseUrl}/updateAll/`, params);
  }

  getRechargeDiscountByAmount(amount: number): Observable<AppliedDiscountResponse> {
    return this.httpClient.get<AppliedDiscountResponse>(`${this._baseUrl}/discountByAmount/${amount}`);
  }

}
