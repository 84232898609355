import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { MatSnackBar as MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { UserRoles } from '../../../../api-authorization/api-authorization.constants';
import { AuthorizeService } from '../../../../api-authorization/authorize.service';
import { LiveAgentEntryLocalStorageKeys, LiveAgentInteractionTypes } from '../../../management/core/constants/live-agent-interaction-types';
import { LocalStorageService } from '../../../management/core/services/common/local-storage.service';
import { BillingInfoService } from '../../../management/core/services/customers/billing-info.service';
import { LiveAgentStatitsticsService } from '../../../management/core/services/live-agent/live-agent-statistics.service';
import { LiveAgentService } from '../../../management/core/services/live-agent/live-agent.service';
import { MakePaymentComponent } from '../../../management/shared-payment/make-payment/make-payment.component';

@Component({
  selector: 'app-management-header',
  templateUrl: './management-header.component.html',
  styleUrls: ['./management-header.component.scss'],
  providers: [LiveAgentService, LiveAgentStatitsticsService, BillingInfoService]
})
export class ManagementHeaderComponent implements OnInit {
  public userRole: string = '';
  public isReseller: boolean = false;
  public availableForUser: UntypedFormControl = new UntypedFormControl(false);
  public availableForTeam: UntypedFormControl = new UntypedFormControl(false);
  public agentEntryTeamId: any = null;
  public agentEntryUserId: any = null;

  private customerCode: string = null;

  constructor(private billingService: BillingInfoService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private authorizeService: AuthorizeService,
    private router: Router,
    private liveAgentService: LiveAgentService,
    private localStorageService: LocalStorageService,
    private statisticsService: LiveAgentStatitsticsService) {
    this.authorizeService.getUser().subscribe(result => {
      this.userRole = result.UserRole.toLowerCase();
      this.customerCode = result.CustomerCode;

      if (result.UserRole == UserRoles.SuperAdmin) {
        this.userRole = 'super-admin'
      }
      if (result.UserRole == UserRoles.Admin) {
        this.userRole = 'admin'
      }
      else if (result.UserRole == UserRoles.Reseller) {
        this.isReseller = true;
      }
      else if (result.UserRole == UserRoles.LiveAgent) {
        this.userRole = "agent";
      }

    });
  }

  ngOnInit() {

    if (this.userRole == "agent") {
      this.getAgentAvailability(LiveAgentInteractionTypes.Task);
      this.getAgentAvailability(LiveAgentInteractionTypes.Team);
    }

    this.availableForUser.valueChanges.subscribe(x => {
      if (x == true) {
        if (this.agentEntryUserId != null) {
          return;
        }

        this.insertAgentEntry(LiveAgentInteractionTypes.Task);
      }
      else {
        if (this.agentEntryUserId == null) {
          return;
        }
        this.checkActiveChats(this.agentEntryUserId, LiveAgentInteractionTypes.Task);
      }
    });

    this.availableForTeam.valueChanges.subscribe(x => {
      if (x == true) {
        if (this.agentEntryTeamId != null) {
          return;
        }

        this.insertAgentEntry(LiveAgentInteractionTypes.Team);
      }
      else {
        if (this.agentEntryTeamId == null) {
          return;
        }
        this.endAgentEntry(this.agentEntryTeamId, LiveAgentInteractionTypes.Team);
      }
    });

    this.localStorageService.onChange().subscribe(key => {
      console.log('on local storage change', key);

      switch (key) {
        case LiveAgentEntryLocalStorageKeys.Task: {
          var value = this.localStorageService.get(LiveAgentEntryLocalStorageKeys.Task);
          if (!!value) {
            break;
          }
          this.availableForUser.setValue(false);
          break;
        }
        case LiveAgentEntryLocalStorageKeys.Team: {
          var value = this.localStorageService.get(LiveAgentEntryLocalStorageKeys.Team);
          if (!!value) {
            break;
          }
          this.availableForTeam.setValue(false);
          break;
        }
      }
    });
  }

  openSettings() {
    this.router.navigate([`management/${this.userRole}/settings`]);
  }

  getAgentAvailability(type: string) {
    //get first from localstorage
    //let entryId = null;

    //if (type == LiveAgentInteractionTypes.Team) {
    //  entryId = this.localStorageService.get(LiveAgentEntryLocalStorageKeys.Team);
    //  this.agentEntryTeamId = entryId;
    //  this.availableForTeam.setValue(entryId !=null);
    //}
    //else {
    //  entryId = this.localStorageService.get(LiveAgentEntryLocalStorageKeys.Task);
    //  this.agentEntryUserId = entryId;
    //  this.availableForUser.setValue(entryId != null);
    //}

    //if (entryId != null && entryId != "") {
    //  return;
    //}

    //get from redis/DB
    this.liveAgentService.getAgentAvailability(type).subscribe(x => {

      if (x.entryId === null) {
        let key = type === LiveAgentInteractionTypes.Team ? LiveAgentEntryLocalStorageKeys.Team : LiveAgentEntryLocalStorageKeys.Task;
        this.localStorageService.remove(key);

        return;
      }

      if (type == LiveAgentInteractionTypes.Team) {
        this.agentEntryTeamId = x.entryId;
        this.availableForTeam.setValue(true);

        //set key in localstorage
        this.localStorageService.set(LiveAgentEntryLocalStorageKeys.Team, x.entryId);

        return;
      }
      if (type == LiveAgentInteractionTypes.Task) {
        this.agentEntryUserId = x.entryId;
        this.availableForUser.setValue(true);

        //set key in localstorage
        this.localStorageService.set(LiveAgentEntryLocalStorageKeys.Task, x.entryId);
        return;
      }
    });
  }

  insertAgentEntry(type: string) {
    this.liveAgentService.insertAgentEntry(type).subscribe(x => {
      if (x == null) {
        if (type == LiveAgentInteractionTypes.Team) {
          this.availableForTeam.setValue(false);
          return;
        }
        if (type == LiveAgentInteractionTypes.Task) {
          this.snackBar.open('You have reached the maximum allowed sessions for this month. You cannot receive and handle new tasks anymore.', 'Ok, got it!', {
            verticalPosition: 'top',
            horizontalPosition: 'center',
            duration: 10000
          });

          this.availableForUser.setValue(false);
          return;
        }
      }
      else {
        if (x.type == LiveAgentInteractionTypes.Team) {
          this.agentEntryTeamId = x.id;
          //set key in localstorage
          this.localStorageService.set(LiveAgentEntryLocalStorageKeys.Team, x.id);
          return;
        }
        if (x.type == LiveAgentInteractionTypes.Task) {
          //set key in localstorage
          this.localStorageService.set(LiveAgentEntryLocalStorageKeys.Task, x.id);

          this.agentEntryUserId = x.id;
          return;
        }

      }

    });
  }

  endAgentEntry(entryId, type) {
    this.liveAgentService.endLiveAgentEntry(entryId).subscribe(x => {
      if (x == false) {
        this.snackBar.open("There was an error.Please try again later", "Ok", {
          duration: 3000,
        });

        if (type == LiveAgentInteractionTypes.Team) {
          this.availableForTeam.setValue(true);
          return;
        }
        if (type == LiveAgentInteractionTypes.Task) {
          this.availableForUser.setValue(true);
          return;
        }
      }
      else {
        if (type == LiveAgentInteractionTypes.Team) {
          this.agentEntryTeamId = null;
          this.localStorageService.remove(LiveAgentEntryLocalStorageKeys.Team);

          return;
        }
        if (type == LiveAgentInteractionTypes.Task) {
          this.agentEntryUserId = null;
          this.localStorageService.remove(LiveAgentEntryLocalStorageKeys.Task);
          return;
        }
      }
    });
  }

  checkActiveChats(entryId, type) {
    //check first if it has active sessions
    this.statisticsService.hasActiveInteractions(type).subscribe(result => {
      if (result == true && type == LiveAgentInteractionTypes.Task) {
        this.availableForUser.setValue(true);
        let message = "Please end all your active tasks with customers before settings as unavailable."

        this.snackBar.open(message, "Ok", {
          duration: 5000,
        });

      }
      else {
        this.endAgentEntry(entryId, type);
      }

    });

  }



  openPayment() {
    this.billingService.getBillingInfo().subscribe(response => {
      if (response.country != null) {
        const dialogRef = this.dialog.open(MakePaymentComponent, {
          minWidth: "40rem",
          maxWidth: '40rem',
          minHeight: "7rem",
          data: response,
          disableClose: true
        });

        dialogRef.afterClosed().subscribe(response => {
          if (response.success) {
            //window.location.reload();

          }

        });
      }
      else {
        this.openBillingMissingSnackBar(`Ops, there is missing data! Complete the billing info to continue`, "Complete Data", 7000);
      }
    })
  }
  openBillingMissingSnackBar(message: string, action: string, duration: number) {
    this.snackBar.open(message, action, {
      duration: duration,
    });

    this.snackBar._openedSnackBarRef.onAction().subscribe(x => {
      this.navigateToBilling()
    });
  }

  navigateToBilling() {
    this.router.navigate([`management/reseller/billing/billing-info`])
  }
}
