import { HttpClient, HttpEventType, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiBaseUrl } from '../../constants/common.constants';
import { IAutopilotSettings, IAutopilotSettingsResponse } from '../../interfaces/ai/autopilot-settings.interface';
import { IStructResponseCall } from '../../interfaces/common/struct-response-call.interface';
import { ISmartDialogTrigger } from '../../interfaces/smart-dialog/smart-dialog-trigger.interface';
import { ISmartDialogDefaultImage } from '../../interfaces/smart-dialog/smart-dialog-default-image.interface';
import { ISmartDialogWebSettings } from '../../interfaces/smart-dialog/smart-dialog-web-settings.interface';
import { map } from 'rxjs/operators';
import { ISmartDialogTemplate } from '../../interfaces/smart-dialog/smart-dialog-template. interface';

@Injectable()
export class AutopilotService {
  private _baseUrl: string = `${ApiBaseUrl}autopilot`;

  constructor(private httpClient: HttpClient) { }

  getAutopilotSettings(botId: any, channel:string): Observable<IAutopilotSettingsResponse> {
    return this.httpClient.get<IAutopilotSettingsResponse>(`${this._baseUrl}/settings/${channel}/${botId}`);
  }

  saveAutopilotSettings(settings: IAutopilotSettings): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this._baseUrl}/settings`, settings);
  }

  getTriggers(filterName: string, botId: any):Observable<ISmartDialogTrigger[]> {
    return this.httpClient.get<ISmartDialogTrigger[]>(`${this._baseUrl}/triggers/${botId}/${filterName}`);
  }

  getIntentTriggers(intentId:any, botId: any): Observable<ISmartDialogTrigger[]> {
    return this.httpClient.get<ISmartDialogTrigger[]>(`${this._baseUrl}/intent-triggers/${botId}/${intentId}`);
  }

  getContexts(botId: any): Observable<string[]> {
    return this.httpClient.get<string[]>(`${this._baseUrl}/contexts/${botId}`);
  }

  saveTrigger(trigger: ISmartDialogTrigger): Observable<IStructResponseCall> {
    return this.httpClient.post<IStructResponseCall>(`${this._baseUrl}/save-trigger`, trigger);
  }

  deleteTrigger(trigger: ISmartDialogTrigger): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this._baseUrl}/delete-trigger`, trigger);
  }

  getTemplates(): Observable<ISmartDialogTemplate[]> {
    return this.httpClient.get<ISmartDialogTemplate[]>(`${this._baseUrl}/templates`);
  }

  createEmptyTrigger(trigger: ISmartDialogTrigger): Observable<IStructResponseCall> {
    return this.httpClient.post<IStructResponseCall>(`${this._baseUrl}/save-empty-trigger`, trigger);
  }

  translate(botId:any, triggerId:any=""): Observable<boolean[]> {
    return this.httpClient.get<boolean[]>(`${this._baseUrl}/translate/${botId}/${triggerId}`);
  }

  //web design

  getDefaultImages(): Observable<ISmartDialogDefaultImage[]> {
    return this.httpClient.get<ISmartDialogDefaultImage[]>(`${this._baseUrl}/default-images`);
  }

  getWebSettings(botId: any): Observable<ISmartDialogWebSettings> {
    return this.httpClient.get<ISmartDialogWebSettings>(`${this._baseUrl}/web-settings/${botId}`);
  }

  updateWebSettings(data: any) {
    return this.httpClient.post<any>(`${this._baseUrl}/update-web-settings`, data, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map((event) => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            const progress = Math.round(100 * event.loaded / event.total);
            return { status: 'progress', message: progress };

          case HttpEventType.Response:
            return event.body;

          default:
            return `Unhandled event: ${event.type}`;
        }
      })
    );
  }
}
