import { Injectable } from '@angular/core';
import { ApiBaseUrl } from '../../constants/common.constants';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IBillingInfo } from '../../interfaces/common/billing-info.interface';
import { ICountry } from '../../interfaces/common/country.interface';

@Injectable()
export class BillingInfoService {
  private _baseUrl = `${ApiBaseUrl}customerbillinginfo`;

  constructor(private httpClient: HttpClient) { }

  getBillingInfo(): Observable<IBillingInfo> {
    return this.httpClient.get<IBillingInfo>(`${this._baseUrl}`);
  }

  updateBillingInfo(billingInfo: IBillingInfo): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this._baseUrl}/update`, billingInfo);
  }

  getCountries(): Observable<ICountry[]> {
    return this.httpClient.get<ICountry[]>(`${this._baseUrl}/countries`);
  }

  getCustomerBillingInfo(customerCode: string): Observable<IBillingInfo> {
    return this.httpClient.get<IBillingInfo>(`${this._baseUrl}/customerBilling/${customerCode}`);
  }

  getCountriesLanguages(): Observable<ICountry[]> {
    return this.httpClient.get<ICountry[]>(`${this._baseUrl}/countries-language`);
  }
}
