import { Component, OnInit } from '@angular/core';
import { UserRoles } from '../../../../api-authorization/api-authorization.constants';
import { AuthorizeService, IUser } from '../../../../api-authorization/authorize.service';

@Component({
  selector: 'app-presentation-header',
  templateUrl: './presentation-header.component.html',
  styleUrls: ['./presentation-header.component.scss']
})
export class PresentationHeaderComponent implements OnInit {

  public module: string = "";
  public user: IUser = null;

  constructor(private authorize: AuthorizeService) { }

  ngOnInit() {
    this.authorize.getUser().subscribe(user => {
      this.user = user;
      if (user == null) {
        return;
      }

      if (user.UserRole == UserRoles.SuperAdmin) {
        this.module = 'super-admin';
      }
      else if (user.UserRole == UserRoles.Admin) {
        this.module = 'admin';
      }
      else {
        this.module = user.UserRole.toLowerCase();
      }

      //this.module = user.UserRole == UserRoles.SuperAdmin ? "admin" : user.UserRole.toLowerCase();

      if (user.UserRole == UserRoles.LiveAgent) {
        this.module = 'agent';
      }
    });
  }
}
