import { Component, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatDialogRef as MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import {
  StripeCardElementOptions,
  StripeElementsOptions
} from '@stripe/stripe-js';
import { ICreateOrderRequest, IPayPalConfig } from 'ngx-paypal';
import { StripeCardComponent, StripeService } from 'ngx-stripe';
import { debounceTime, distinctUntilChanged, startWith, map } from 'rxjs/operators';
import { TaxStatuses } from '../../core/constants/billing.constants';
import { PaymentMethods, PaymentStatus } from '../../core/constants/payment.constants';
import { IBillingInfo } from '../../core/interfaces/common/billing-info.interface';
import { AppliedDiscountResponse } from '../../core/interfaces/payment/applied-discount-response.interface';
import { IPaymentTransactionResponse } from '../../core/interfaces/payment/payment-transaction-response';
import { PaymentsService } from '../../core/services/common/payments.service';
import { RechargeDiscountService } from '../../core/services/common/recharge-discount.service';
import { MakePaymentFormService } from '../make-payment-form.service';
import { ICountry } from '../../core/interfaces/common/country.interface';
import { BillingInfoService } from '../../core/services/customers/billing-info.service';
import { IPaymentInstrunctions, IPaymentIntentRequest } from '../../core/interfaces/payment/payment-intent-request';
import { Observable } from 'rxjs/internal/Observable';


//declare var Stripe: any;


@Component({
  selector: 'app-make-payment',
  templateUrl: './make-payment.component.html',
  styleUrls: ['./make-payment.component.scss'],
  providers: [MakePaymentFormService, BillingInfoService,
    PaymentsService, RechargeDiscountService]
})


export class MakePaymentComponent implements OnInit {
  public payPalConfig?: IPayPalConfig;

  @ViewChild(StripeCardComponent) card: StripeCardComponent;
  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Muli", sans-serif',
        lineHeight: '1.2em',
        fontSize: '1em',
      }
    },
    hidePostalCode: true
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'en'
  };

  private isStripeCustomer: boolean = false;
  private stripeCustomerId: string = null;
  private paymentIntent = null;
  private paypalClientId: string = null;
  public minAmount: number = 1;

  public loading: boolean = false;
  public success: boolean = false;
  public hideDropdown: boolean = false;
  public cardSaved = null;
  public confirmation: any;
  public paymentMethodsList: any[] = [];
  public isCard: boolean = true;
  public validAmount: boolean = false;
  public vat: number = 22;
  public adminCountry: string = null;
  public calculateVAT: boolean = false;
  public appliedDiscount: AppliedDiscountResponse = null;
  public billingInfo: IBillingInfo = null;

  public filteredOptions: Observable<ICountry[]>;
  public countries: ICountry[] = [];
  private cardFormElements: string[] = ["name", "country", "address", "postalCode", "city", "state"];
  public paymentInstructions: IPaymentInstrunctions = null;

  private paymentIntentResponse = null;


  constructor(private dialogRef: MatDialogRef<MakePaymentComponent>,
    private paymentService: PaymentsService,
    private titleService: Title, private billingInfoService: BillingInfoService,
    private formService: MakePaymentFormService,
    private rechargeDiscountService: RechargeDiscountService,
    private stripeService: StripeService,
    @Inject(MAT_DIALOG_DATA) public data: IBillingInfo | null) {
    this.titleService.setTitle("Dillo | Recharge");

    if (data == null) {
      return;
    }

    this.getPaymentSettings(data);
  }

  ngOnInit() {
    this.amount.valueChanges.pipe(
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe(value => {


      if (value >= this.minAmount) {
        this.validAmount = true;
      }
      else {
        this.validAmount = false;
      }

      let valueNr: number = +value;
      let VATValue: number = (valueNr * (this.vat / 100));
      this.valueVAT.setValue(VATValue.toFixed(2));
      let amoutwithVat = valueNr + VATValue;

      if (this.calculateVAT == false) {
        this.totalAmount.setValue(value);
      }
      else {
        this.totalAmount.setValue(amoutwithVat.toFixed(2));
      }

      if (value >= this.minAmount) {
        this.rechargeDiscountService.getRechargeDiscountByAmount(value)
          .subscribe(response => {
            this.appliedDiscount = response;
          });
      }
    });


    this.paymentType.valueChanges.subscribe(values => {
      if (values == PaymentMethods.Paypal.toLowerCase()) {
        this.isCard = false;
        this.paymentMethod.setValue(null);
        this.amount.disable({ onlySelf: true });

        this.formService.clearValidators(this.cardFormElements);
        //this.paymentType.disable();
        //console.log('value', this.amount.value);
        this.initConfig();
      }
      else
        if (values == "bank-transfer") {
          this.isCard = false;
          this.formService.clearValidators(this.cardFormElements);
        }
        else {
          //const childElements = this.paypalElement.nativeElement.childNodes;
          //for (let child of childElements) {
          //  this.renderer.removeChild(this.paypalElement.nativeElement, child)
          //}
          this.isCard = true;
          this.formService.setValidators(this.cardFormElements, this.countries);
          this.amount.enable({ onlySelf: true });
        }
    });



    this.paymentMethod.valueChanges.subscribe(value => {
      if (value == 0) {
        this.formService.setValidators(this.cardFormElements, this.countries);
      }
      else {
        this.formService.clearValidators(this.cardFormElements);
      }
    })

    this.billingInfoService.getCountries().subscribe(x => {
      this.countries = x;
      this.country.setValidators(this.formService.forbiddenNamesValidator(this.countries));
    });

    this.billingInfoService.getBillingInfo().subscribe(x => {
      this.billingInfo = x;
    })

    this.filteredOptions = this.country.valueChanges.pipe(
      startWith(''),
      map(value => this.filter(value))
    );
  }

  get form() {
    return this.formService.form;
  }

  get amount() {
    return this.formService.getFormControl("amount");
  }

  get paymentMethod() {
    return this.formService.getFormControl("paymentMethod");
  }

  get paymentType() {
    return this.formService.getFormControl("paymentType");
  }

  get totalAmount() {
    return this.formService.getFormControl("totalAmount");
  }

  get valueVAT() {
    return this.formService.getFormControl("valueVAT");
  }

  get description() {
    return this.formService.getFormControl("description");
  }

  get country() {
    return this.formService.getFormControl("country");
  }

  get name() {
    return this.formService.getFormControl("name");
  }

  get address() {
    return this.formService.getFormControl("address");
  }

  get city() {
    return this.formService.getFormControl("city");
  }

  get state() {
    return this.formService.getFormControl("state");
  }

  get postalCode() {
    return this.formService.getFormControl("postalCode");
  }

  get acceptTerms() {
    return this.formService.getFormControl("acceptTerms");
  }



  pay() {
    this.loading = true;
    let paymentMethod = this.paymentMethod.value != 0 && this.paymentType.value=="card" ? this.paymentMethod.value : "";
    let selectedCountryObj = this.countries.find(x => x.name == this.country.value);
    const paymentRequest: IPaymentIntentRequest = {
      amountWithVat: this.totalAmount.value,
      creditsValueTxt: this.amount.value,
      description: this.description.value,
      paymentMethodId: paymentMethod,
      cardholderName: this.paymentMethod.value != 0 ? "" : this.name.value,
      email: this.billingInfo.email,
      telephoneNumber: this.billingInfo.telephoneNumber,
      countryCode: selectedCountryObj != undefined && selectedCountryObj != null ? selectedCountryObj.twoLetterISOName : "",
      address: this.paymentMethod.value != 0 ? "" : this.address.value,
      city: this.paymentMethod.value != 0 ? "" : this.city.value,
      postalCode: this.paymentMethod.value != 0 ? "" : this.postalCode.value,
      state: this.paymentMethod.value != 0 ? "" : this.state.value,
      paymentMethod: this.paymentType.value == "card" ? "Card" : "BankTransfer"
    }

    this.paymentService.createPaymentIntent(paymentRequest).subscribe(x => {
      if (x == null) {
        this.loading = false;
        this.confirmation = "An Arror occured, please refresh and try again!"
      }
      else
        if (this.paymentType.value == "card") {
          this.chargeCard(x.client_secret, this.card);
        }
        else {
          this.loading = false;
          if (x.next_action && x.next_action.display_bank_transfer_instructions != null && x.next_action.display_bank_transfer_instructions.financial_addresses != null) {
            let response = x.next_action.display_bank_transfer_instructions.financial_addresses[0];

            if (response.iban != null) {
              this.paymentInstructions = {
                iban: response.iban.iban,
                companyName: response.iban.account_holder_name,
                bic: response.iban.bic,
                reference: x.next_action.display_bank_transfer_instructions.reference
              }
            }

            this.amount.disable({ onlySelf: true });

          }
          
        }

    });

  }

  chargeCard(client_secret, stripeCard) {
    this.loading = true;
    console.log("stripeCard=", this.card.element, "paymentIntent=", client_secret);

    var paymentMethod;

    //pay with a new card
    if (this.paymentMethod.value == '0') {
      let selectedCountryObj = this.countries.find(x => x.name == this.country.value);
      paymentMethod = {
        card: this.card.element,
        billing_details: {
          name: this.name.value,
          email: this.billingInfo.email,
          phone: this.billingInfo.telephoneNumber,
          address: {
            city: this.city.value,
            country: selectedCountryObj.twoLetterISOName,
            line1: this.address.value,
            postal_code: this.postalCode.value,
            state: this.state.value

          },
        },
      }
    }
    else {
      paymentMethod = client_secret.payment_method;
    }

    this.stripeService.confirmCardPayment(client_secret, {
      payment_method: paymentMethod
      //setup_future_usage: 'off_session'
    }).subscribe(result => {
      this.loading = false;
      if (result.error) {
        // Show error 
        //console.log(result.error.message);
        this.success = false;
        this.confirmation = result.error.message;
      } else {
        if (result.paymentIntent.status === 'succeeded') {
          // console.log(result.paymentIntent);
          this.confirmation = "Payment succesfully made!";
          this.success = true;
          this.paymentIntent = result.paymentIntent;
        }
      }
    });

  }

  saveCard(event) {
    if (event.checked && this.paymentIntent != null) {
      this.loading = true;

      //create setup intent on server an return clientSecret
      this.paymentService.createSetupIntent().subscribe(intentClientSecret => {
        if (intentClientSecret == null) {
          return;
        }

        //confirm card setup on stripe
        this.stripeService.confirmCardSetup(
          intentClientSecret,
          {
            payment_method: {
              card: this.card.element,
              metadata: { "IsDefault": `false` }
            },
          }
        ).subscribe(result => {
          if (result.error) {
            this.cardSaved = false;
            this.loading = false;
          } else {
            // The setup has succeeded. Display a success message and send
            // result.setupIntent.payment_method to server to save the
            // card to a Customer
            this.paymentService.saveCard(result.setupIntent.payment_method, this.stripeCustomerId, false).subscribe(x => {
              this.cardSaved = x;
              this.loading = false;
            })

          }
        });

      });
    }
  }

  getPaymentSettings(data) {

    this.loading = true;

    this.paymentService.getPaymentSettings().subscribe(x => {
      if (x == null) {
        return;
      }

      this.paypalClientId = x.payPalClientId;
      this.vat = x.vat;
      this.adminCountry = x.country;
      this.minAmount = x.minRechargeValue;

      //set min value
      this.formService.setMinAmount(this.minAmount);

      //console.log("checkVAT",data);
      this.calculateVAT = this.checkIfVatApplies(data);

      //check if this customer is a StripeCustomer
      this.getStripeInfo(x.stripeCustomerId);
      //console.log("paymnetSettings=", x,x.stripeCustomerId);
      this.loading = false;
    });
  }

  getStripeInfo(stripeId: string) {
    this.stripeCustomerId = stripeId;
    if (stripeId != null) {
      this.isStripeCustomer = true;

      this.getPaymentMethods();
    }
    else {
      this.paymentMethod.setValue(0);
      this.hideDropdown = true;
    }

  }

  getPaypalInfo() {
    this.paymentService.getPaypalInfo().subscribe(x => {
      if (x != null) {
        this.paypalClientId = x;
      }
      return
    });
  }

  getPaymentMethods() {
    this.paymentService.getPaymentMethods(this.stripeCustomerId).subscribe(x => {
      this.paymentMethodsList = x;
      if (x.length == 0) {
        this.paymentMethod.setValue(0);
        this.hideDropdown = true;
      }
      x.map(payment => {
        if (payment.isDefault) {
          this.paymentMethod.setValue(payment.id);
        }
      })
    })
  }

  cancel() {
    this.dialogRef.close({ "success": this.success, "cardSaved": this.cardSaved, "stripeCustomerId": this.stripeCustomerId });
  }

  private initConfig(): void {
    this.payPalConfig = {
      currency: 'EUR',
      clientId: this.paypalClientId,
      createOrderOnClient: (data) => <ICreateOrderRequest>{
        intent: 'CAPTURE',
        application_context: {
          payment_method: {
            payer_selected: 'PAYPAL'
          }
        },
        purchase_units: [
          {
            amount: {
              currency_code: 'EUR',
              value: this.totalAmount.value,
            }
          }
        ]
      },
      advanced: {
        commit: 'true'
      },
      style: {
        label: 'paypal',
        shape: "pill",
        layout: 'horizontal',
        color: "silver",
        tagline: false
      },
      onApprove: (data, actions) => {
        //console.log('onApprove - transaction was approved, but not authorized', data, actions);
        actions.order.get().then(details => {
          //console.log('onApprove - you can get full order details inside onApprove: ', details);
        });
      },
      onClientAuthorization: (data: any) => {
        console.log("data", data);
        const paymentResponse: IPaymentTransactionResponse = {
          createdTime: data.create_time,
          updatedTime: data.update_time,
          status: data.status == "COMPLETED" ? PaymentStatus.Succeeded : PaymentStatus.Failed,
          amount: + this.amount.value,
          paymentMethod: PaymentMethods.Paypal,
          amountWithVat: + (data.purchase_units[0].amount.value),
          paypalTransactionId: data.id
        }
        this.paymentService.processPayPalPayment(paymentResponse).subscribe(x => {
          this.loading = false;
          if (x == true) {
            this.success = x;
            this.confirmation = "Payment succesfully made!"
          }
          else {
            this.success = x;
            this.confirmation = "Please contact our support team to solve your problem!"
          }

        })
      },
      onCancel: (data, actions) => {
        this.cancel();
      },
      onError: err => {
        this.success = false;
        this.confirmation = err;
      },
      onClick: (data, actions) => {
        this.loading = true;
      },
    };
  }

  checkIfVatApplies(data: IBillingInfo): boolean {
    //console.log("data.country=", data.country, "taxstatus=", data.taxStatus, "adminCountry=", this.adminCountry, "check if=", (data.isCompany == false || (data.country == this.adminCountry && data.taxStatus == TaxStatuses.Taxable)));

    if (data.taxStatus == TaxStatuses.Taxable) {
      if (data.isCompany) {
        if (data.country.toLowerCase() == this.adminCountry.toLowerCase()) {

          return true;
        }
      }
      else {
        //console.log("client-true")

        return true;
      }
    }
    return false;
  }

  private filter(value: any): ICountry[] {
    if (value == null) {
      value = '';
    }
    const filterValue = value.toLocaleLowerCase();

    return this.countries.filter(x => x.name.toLocaleLowerCase().indexOf(filterValue) === 0);
  }
}
