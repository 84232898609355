//const _gsm7Regex = new RegExp(/^[@£$¥èéùìòÇØøÅå_ÆæßÉ!""#%&'()*+,\-.\/0123456789:;<=>? ¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà^{}\[~\]|€]+$/gm);

// this method checks if message has special characters
export function IsGSM7Alphabet(message) {
  
  //const _gsm7Regex = new RegExp(/^[@£$¥èéùìòÇØøÅå_ÆæßÉ!""#%&'()*+,\-.\/0123456789:;<=>? ¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà^{}\[~\]|€]+$/gm);
  const _gsm7Regex = new RegExp(/^[@£$¥èéùìòÇØøÅå_ÆæßÉ!""#%&'()*+,\-./0123456789:;<=>? ¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà^{}\\[~\]|€]+$/gm);


  if (message == null || message.trim() == '') {
    return true;
  }
  return _gsm7Regex.test(`${message}`);

}
export function getLanguageNameByCode(languageCode: string): string {
  try {
    const lang = languageCode.split('-')[0];

    const displayNames = new Intl.DisplayNames(['en'], { type: 'language' });

    // Return only the language name
    return displayNames.of(lang) || languageCode;
  } catch (error) {
    // Return the original code if there's an error (invalid language code)
    return languageCode;
  }
}
