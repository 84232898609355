<header>
  <nav class='navbar navbar-expand-sm navbar-toggleable-sm fixed-top box-shadow'>
    <div class="container-fluid">
      <!--<a class="navbar-brand" [routerLink]='["/"]'>Ndoct.Website</a>-->
      <!--<a class="logo" [routerLink]='["/"]'>-->
      <a class="logo">

      </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target=".navbar-collapse" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="navbar-collapse collapse d-sm-inline-flex flex-sm-row-reverse">
        <app-login-menu></app-login-menu>
        <ul class="navbar-nav flex-growv align-center">
          <!--<li class="nav-item" [routerLinkActive]='["link-active"]' [routerLinkActiveOptions]='{ exact: true }'>-->
          <li *ngIf="userRole=='agent'" class="nav-item">
            <mat-slide-toggle class="example-margin"
                              [color]="accent"
                              [formControl]="availableForUser">
              <span class="slider-label">
                <span *ngIf="availableForUser.value==true">Available for customers</span>
                <span *ngIf="availableForUser.value==false">Unavailable for customers</span>
              </span>
            </mat-slide-toggle>
          </li>
          &nbsp;
          &nbsp;
          &nbsp;
          <li *ngIf="userRole=='agent'" class="nav-item">
            <mat-slide-toggle class="example-margin"
                              [color]="accent"
                              [formControl]="availableForTeam">
              <span class="slider-label">
                <span *ngIf="availableForTeam.value==true">Available for team</span>
                <span *ngIf="availableForTeam.value==false">Unavailable for team</span>
              </span>
            </mat-slide-toggle>
          </li>
          &nbsp;
          &nbsp;
          <li class="nav-item" *ngIf="userRole!='agent'">
            <a class="nav-link" href='https://docs.dillo.cloud' target="_blank">Docs</a>
            <!--<a class="nav-link" href='https://netdoctrine-dev-docs.azurewebsites.net' target="_blank">Docs</a>-->
          </li>
          <!--<li class="nav-item" [routerLinkActive]='["link-active"]' [routerLinkActiveOptions]='{ exact: true }'>
    <a class="nav-link" [routerLink]='["/help"]'>Help</a>
  </li>-->
          <!--<li class="nav-item" [routerLinkActive]='["link-active"]' [routerLinkActiveOptions]='{ exact: true }'>
    <a class="nav-link" [routerLink]='["/support"]'>Support</a>
  </li>-->
          <li class="nav-item" *ngIf="userRole!='agent'">
            <a class="nav-link" href='http://support.dillo.cloud/' target="_blank">Support</a>
          </li>

        </ul>
        <div class="mr-4">
          <a mat-raised-button *ngIf="isReseller" class="recharge-btn" (click)="openPayment()">
            <small class="pt-1 mr-1"><i class="lni lni-plus"></i></small>
            Recharge
          </a>
        </div>
      </div>
    </div>
  </nav>
</header>
