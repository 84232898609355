
<div *ngIf="id!=null">
  <h3>Multi-channel actions</h3>

  <div><b>RequestId: </b>{{id}}</div>
  <div><b>Telephone Number: </b>{{telephoneNumber}}</div>
  <div *ngIf="callDirection=='Inbound' && did!=null"><b>DID: </b>{{did}}</div>
</div>


<div class="items-table-container">
  <table mat-table [dataSource]="dataSource" class="full-width">

    <ng-container matColumnDef="channel">
    <th mat-header-cell *matHeaderCellDef>Channel</th>
    <td mat-cell *matCellDef="let element">{{element.channel}}</td>

    </ng-container>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>Request id</th>
      <td mat-cell *matCellDef="let element">{{element.id}}</td>

    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>

</div>
