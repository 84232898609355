export const LiveAgentActivityLogTypes = {
  TaskAccepted: "TaskAccepted",
  TaskDeclined: "TaskDeclined",
  TaskAbandoned: "TaskAbandoned",
  TaskCompleted: "TaskCompleted",
  InviteRequest: "InviteRequest",
  TransferRequest: "TransferRequest",
  Login: "Login",
  Logout: "Logout",
  AvailableForTasks: "AvailableForTasks",
  UnavailableForTasks: "UnavailableForTasks",
  AvailableForTeam: "AvailableForTeam",
  UnavailableForTeam: "UnavailableForTeam",
  AgentActivity: "AgentActivity",
  LeaveInvitation:"LeaveInvitation"
}
