import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { getLanguageNameByCode } from '../../../../core/functions/string-extention';
import { IAutomaticTextTranslation } from '../../../../core/interfaces/ai/automatic-text-translation';

@Component({
  selector: 'app-translation-billing-overview',
  templateUrl: './translation-billing-overview.component.html',
  styleUrl: './translation-billing-overview.component.scss'
})
export class TranslationBillingOverviewComponent implements OnInit {

  public element: IAutomaticTextTranslation;

  constructor(private dialogRef: MatDialogRef<TranslationBillingOverviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    if (data == null) {
      return;
    }

    this.element = data;
  }

  ngOnInit() {

  }

  getLanguageName(code: string) {
    return getLanguageNameByCode(code);
  }

}
