import { Injectable } from '@angular/core';
import { ApiBaseUrl } from '../../constants/common.constants';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IPaymentMethod } from '../../interfaces/payment/payment-method';
import { IActivityRequest } from '../../interfaces/common/activity-request.interface';
import { IPaymentHistory } from '../../interfaces/payment/payment-history.interface';
import { DataSourceResponse } from '../../interfaces/data/datasourceresponse.interface';
import { ICustomerAutoRechargeInfo } from '../../interfaces/customer/customer-auto-recharge-info';
import { IPaymentTransactionResponse } from '../../interfaces/payment/payment-transaction-response';
import { IPaymentSettings } from '../../interfaces/payment/payment-settings.interface';
import { IPaymentIntentRequest } from '../../interfaces/payment/payment-intent-request';

@Injectable()
export class PaymentsService {
  private _baseUrl=`${ApiBaseUrl}payments`

  constructor(private httpClient:HttpClient) { }

  createPaymentIntent(paymentIntentRequest: IPaymentIntentRequest): Observable<any> {
    return this.httpClient.post<any>(`${this._baseUrl}/createPaymentIntent`, paymentIntentRequest);
  }

  saveCard(paymentMethod: any, stripeCustomerId: string, isDefault:boolean): Observable<boolean> {
    const params = new HttpParams().set("paymentMethodId", paymentMethod).set("isDefaultString",isDefault.toString());
    return this.httpClient.post<boolean>(`${this._baseUrl}/updateCustomer/${stripeCustomerId}`, params);
  }

  isStripeCustomer(): Observable<string> {
    return this.httpClient.get<string>(`${this._baseUrl}`);
  }

  getStripeKey(): Observable<string> {
    return this.httpClient.get<string>(`${this._baseUrl}/stripeKey`);
  }

  getPaymentMethods(stripeCustomerId: string): Observable<IPaymentMethod[]> {
    return this.httpClient.get<IPaymentMethod[]>(`${this._baseUrl}/paymentMethods/${stripeCustomerId}`);
  }

  deletePaymentMethod(paymentMethodId: string, stripeCustomerId: string): Observable<string> {
    const params = new HttpParams().set("stripeCustomerId", stripeCustomerId);
    return this.httpClient.post<string>(`${this._baseUrl}/detachPaymentMethod/${paymentMethodId}`,params);
  }

  createSetupIntent(): Observable<string> {
    return this.httpClient.get<string>(`${this._baseUrl}/createSetupIntent`);
  }

  updatePaymentMethod(customerStripeId:string, paymentMethod:IPaymentMethod): Observable<any> {
    return this.httpClient.post<any>(`${this._baseUrl}/updatePaymentMethod/${customerStripeId}`, paymentMethod);
  }

  getPaymentHistory(activity: IActivityRequest): Observable<DataSourceResponse<IPaymentHistory>> {
    return this.httpClient.post<DataSourceResponse<IPaymentHistory>>(`${this._baseUrl}/paymentHistory`, activity);
  }

  getAutoRechargeInfo(): Observable<ICustomerAutoRechargeInfo> {
    return this.httpClient.get<ICustomerAutoRechargeInfo>(`${this._baseUrl}/getCustomerAutoRechargeInfo`)
  }

  updateAutoRechargeInfo(autoRechargeInfo: ICustomerAutoRechargeInfo):Observable<boolean> {
    return this.httpClient.post<boolean>(`${this._baseUrl}/updateCustomerAutoRechargeInfo`, autoRechargeInfo);
  }

  processPayPalPayment(paymentTransaction: IPaymentTransactionResponse): Observable<boolean> {
   
    return this.httpClient.post<boolean>(`${this._baseUrl}/paypalPayment`, paymentTransaction);
  }

  getPaypalInfo(): Observable<string> {
    return this.httpClient.get<string>(`${this._baseUrl}/paypalClient`);
  }

  getPaymentSettings(): Observable<IPaymentSettings> {
    return this.httpClient.get<IPaymentSettings>(`${this._baseUrl}/paymentSettings`)
  }

}
