import { Component, EventEmitter, HostListener, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BotTrainingStatusName } from '../../../../core/constants/bot-training-status-name';
import { IBot } from '../../../../core/interfaces/ai/bot.interface';
import { AiManagementService } from '../../../../core/services/ai/ai-management.service';
import { CustomerService } from '../../../../core/services/customers/customer.service';
import { RandomMessageComponent } from '../../random-message/random-message.component';
import { PublishBotDialogComponent } from '../publish-bot-dialog/publish-bot-dialog.component';

@Component({
  selector: 'app-common-bot-card',
  templateUrl: './common-bot-card.component.html',
  styleUrls: ['./common-bot-card.component.scss'],
  providers: [AiManagementService, CustomerService]
})
export class CommonBotCardComponent implements OnInit, OnDestroy {
  public training: boolean = false;
  public checkTraining: boolean = false;
  public customerEnableChatbot: boolean = false;
  public loadedBot: boolean = false;
  public trainBtnTooltip: string = null;
  public enableChatBot = new UntypedFormControl();

  public windowWidth: number = 1920;
  public smallViewportLimit: number = 1495;

  public botId: any = null;
  public bot: IBot = null;
  private reloadBotTimeout;
  private subscription = new Subscription();

  @Output() disableChatbot = new EventEmitter<boolean>();

  // Listen to window resize events
  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.windowWidth = window.innerWidth;
  }

  constructor(private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private botService: AiManagementService,
    private router: Router,
    private customerService: CustomerService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.windowWidth = window.innerWidth;

    this.botId = this.route.snapshot.paramMap.get("id");

    if (this.botId != null) {
      this.getBot();
    }

    this.enableChatBot.setValue(localStorage.getItem(`${this.botId}-currentBotEnableChat`) == 'true');

    this.enableChatBot.valueChanges.subscribe(value => {
      if (this.bot != null && this.bot.enableChatbot != value) {
        this.bot.enableChatbot = value;
        this.botService.updateBot(this.bot).subscribe({
          next: response => {
            localStorage.setItem(`${this.botId}-currentBotEnableChat`, `${value}`.toLowerCase());
            //location.reload();
            this.disableChatbot.emit(value);
          }
        })
      }
    });

    this.customerService.getCustomerFromCache().subscribe({
      next: response => {
        if (response == null) {
          return;
        }
        
        this.customerEnableChatbot = response.enableChatbot;
      }
    })

  }

  ngOnDestroy() {
    this.destroyTimeout();
  }

  public destroyTimeout() {
    //console.log("ngOnDestroy", this.reloadBotTimeout);
    clearTimeout(this.reloadBotTimeout);
    this.reloadBotTimeout = null;
    this.subscription.unsubscribe();
  }

  getBot() {
    if (this.botId == null) {
      return;
    }
    
    this.subscription.add(
      this.botService.getBot(this.botId).subscribe(x => {
        this.bot = x

        if (x.providerCode == "DialogFlow") {
          this.trainBtnTooltip = 'This bot has automatic training';

          this.checkTraining = false;

          this.reloadBotTimeout = setTimeout(() => this.getBot(), 3000);

          return;
        }

        if (x.trainingStatus == BotTrainingStatusName.Completed) {
          this.trainBtnTooltip = 'Trained';

          if (this.checkTraining == true) {
            this.openSnackBar(`Bot training has completed successfully! You can now publish your bot.`, "Ok, got it!", 10000);
          }

          this.checkTraining = false;

          this.reloadBotTimeout = setTimeout(() => this.getBot(), 3000);

          return;
        }

        if (x.trainingStatus == BotTrainingStatusName.NeedsTraining) {
          this.trainBtnTooltip = 'The training process incurs costs, so it should be used only when essential.';

          if (x.status == 'TrainingFailed' && this.checkTraining == true) {
            if (x.failedIntents != null && x.failedIntents != '') {
              this.openSnackBar(`The following intents don't have any examples: ${x.failedIntents}`, "Ok, got it!", 7000);

            }
            else {
              this.openSnackBar(`Training Failed. Try again!`, "Ok, got it!", 3000);
            }
          }

          this.checkTraining = false;
          this.reloadBotTimeout = setTimeout(() => this.getBot(), 3000);
          return;

        }

        if (x.trainingStatus == BotTrainingStatusName.InProgress) {
          this.trainBtnTooltip = 'Training';

          //60 seconds
          this.reloadBotTimeout = setTimeout(() => this.getBot(), 60000);
        }
      })
    );
    

  }

  trainBot() {
    this.bot.trainingStatus = BotTrainingStatusName.InProgress;
    this.trainBtnTooltip = 'Training';
    this.checkTraining = true;
    this.subscription.add(
      this.botService.trainBot(this.bot).subscribe(x => {

        if (x != null && x.entity == true) {
          this.reloadBotTimeout = setTimeout(() => this.getBot(), 5000);

          this.openSnackBar(`Your training request is in the queue and may take several minutes. Once completed, publish your bot to apply the latest updates.`, "Ok, got it!", 7000);
          return;
        }

        if ((x != null && x.entity == false && x.errorCode != null && x.errorCode != "")) {
          this.openSnackBar(`${x.errorCode}`, "Ok, got it!", 7000);
          this.bot.trainingStatus = BotTrainingStatusName.NeedsTraining;
          this.trainBtnTooltip = 'Train';
          return;

        }
        this.bot.trainingStatus = BotTrainingStatusName.NeedsTraining;
        this.trainBtnTooltip = 'Train';
        this.openSnackBar(`Bot could not be trained!`, "Ok, got it!", 2000);

      })
    );
  }

  openPublishDialog() {
    const dialogRef = this.dialog.open(PublishBotDialogComponent, {
      minHeight: '17rem',
      minWidth: '30rem',
      disableClose: true,
      data: this.bot
    });

    dialogRef.afterClosed().subscribe(response => {
      this.handleResponse(response);
    });
  }

  handleResponse(response) {
    if (response === undefined || !response.saved) {
      return;
    }
    if (response.saved == true) {

      var dialogMessage = `<div>Version <b>${this.bot.currentVersion}</b> published successfully to <b> ${response.bot.botEnvironmentName} </b> environment.</div>`;
      const dialogRef = this.dialog.open(RandomMessageComponent, {
        maxWidth: "25rem",
        minHeight: "7rem",
        data: dialogMessage
      });
      var version = parseInt(this.bot.currentVersion) + 1;
      this.bot.currentVersion = version.toString();
      this.bot.status = "Published";

      localStorage.setItem("currentBotVersionId", this.bot.currentVersion);

      var currentUrl = this.router.url;
      var refreshUrl = currentUrl.indexOf('someRoute') > -1 ? '/someOtherRoute' : '/someRoute';
      this.router.navigateByUrl(refreshUrl).then(() => this.router.navigateByUrl(currentUrl));
    }

  }
  get tooltipText(): string {
    const extraLanguages = this.bot.multiLanguageNames;
    return extraLanguages.map(l => l.name).join(', ');
  }

  openSnackBar(message: string, action: string, duration: number) {
    this.snackBar.open(message, action, {
      duration: duration
    });
  }

}
