<div *ngIf="bot!=null" class="bot-header mat-elevation-z5">
  <div class="d-flex justify-content-between align-items-center bot-header-container">

    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex justify-content-center align-items-center mr-5">

        <ng-container *ngIf="bot.trainingStatus == 'InProgress';else standard">
          <h2>
            {{bot.name.slice(0,10)}}<span *ngIf="bot.name.length > 10" [matTooltip]="bot.name">...</span>
          </h2>
        </ng-container>

        <ng-template #standard>
          <h2>
            {{bot.name.slice(0,16)}}<span *ngIf="bot.name.length > 16" [matTooltip]="bot.name">...</span>
          </h2>
        </ng-template>

        <!--<span>
          &nbsp;(v {{bot.currentVersion}})
        </span>-->
      </div>

      <span class="mx-5">
        <b>Multilanguage:&nbsp;</b>
        <ng-container *ngIf="bot.multilingual; else default">Yes</ng-container>
        <ng-template #default>No</ng-template>
      </span>

      <span *ngIf="bot.multilingual && windowWidth > smallViewportLimit" class="mx-5">
        <b>Languages:</b>
        <ng-container *ngIf="bot.multiLanguageNames.length <= 2; else more">
          <ng-container *ngFor="let language of bot.multiLanguageNames; let last = last">
            {{ language.name }}<span *ngIf="!last">,&nbsp;</span>
          </ng-container>
        </ng-container>

        <ng-template #more>
          {{ bot.multiLanguageNames[0].name }},
          {{ bot.multiLanguageNames[1].name }}
          <span [matTooltip]="tooltipText">...</span>
        </ng-template>
      </span>
    </div>

    <div>
      <div class="float-right">
        <span class="mr-3 pt-2" *ngIf="customerEnableChatbot==true">
          <mat-slide-toggle [formControl]="enableChatBot">Enable chatbot</mat-slide-toggle>
        </span>
        <span [matTooltip]='trainBtnTooltip'>
          <button mat-raised-button class="ml-3" (click)="trainBot()" color="primary" [disabled]="bot.providerCode=='DialogFlow' || bot.needsTraining==false || checkTraining || bot.trainingStatus!='NeedsTraining'">
            <span *ngIf="bot.trainingStatus=='NeedsTraining'">Train</span>
            <span *ngIf="bot.trainingStatus=='Completed'">Trained</span>
            <div *ngIf="bot.trainingStatus=='InProgress'" class="lds-facebook"><div></div><div></div><div></div></div>
            <span *ngIf="bot.trainingStatus=='InProgress'">Training</span>

          </button>
        </span>

        <span [matTooltip]="bot.providerCode!='DialogFlow' && bot.trainingStatus=='InProgress' ? 'This bot is in training status' : bot.status=='Published'?'Already published, make other changes':'Publish bot'" class="ml-4">
          <button mat-raised-button (click)="openPublishDialog()" color="accent" [disabled]="bot.status=='Published' || bot.trainingStatus=='InProgress'"><span>Publish</span></button>
        </span>
      </div>
    </div>
  </div>
</div>
