<ul class="navbar-nav" *ngIf="isAuthenticated | async">
  <li class="nav-item">
    <button [matMenuTriggerFor]="menu" class="btn-user" [ngStyle]="{ 'backgroundImage': user.ProfileImage !== undefined && user.ProfileImage !== null && user.ProfileImage !== '' ? 'url(' + user.ProfileImage + ')' : 'none' }">
      <i *ngIf="user.ProfileImage === undefined || user.ProfileImage === null || user.ProfileImage === ''" class="lni-user"></i>
    </button>
    <mat-menu #menu="matMenu" class="login-nav-menu">
      <span mat-menu-item>{{ user.name }}</span>
      <hr />
      <!--<button mat-menu-item class="align-center" (click)="openBilling()" *ngIf="isAdmin==false">Billing</button>
      <button mat-menu-item class="align-center" (click)="openSettings()">Settings</button>-->
      <button mat-menu-item class="align-center" (click)="logout()">
        <a class="nav-link" title="Logout">Logout</a>
      </button>
    </mat-menu>
  </li>
  <!--<li class="nav-item">
    <a class="nav-link" [routerLink]='["/authentication/profile"]' title="Manage">Hello {{ userName | async }}</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" [routerLink]='["/authentication/logout"]' [state]='{ local: true }' title="Logout">Logout</a>
  </li>-->
</ul>
<ul class="navbar-nav" *ngIf="!(isAuthenticated | async)">
  <!--<li class="nav-item">
        <a class="nav-link" [routerLink]='["/authentication/register"]'>Register</a>
    </li>-->
    <li class="nav-item">
        <a class="nav-link" [routerLink]='["/authentication/login"]'>Login</a>
    </li>
</ul>
