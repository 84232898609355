export const ExtraService= {
  CallRecording:'CallRecording',
  Transcription:'Transcription',
  AutomaticSpeechRecognition: 'AutomaticSpeechRecognition',
  LanguageDetection:"LanguageDetection",
  CallRecordingPurchase:'CallRecordingPurchase',
  TranscriptionPurchase:'TranscriptionPurchase',
  AutomaticSpeechRecognitionPurchase: 'AutomaticSpeechRecognitionPurchase',
  VoiceCallConference: 'VoiceCallConference',
  AgentConference: "AgentConference",
  AgentPhone: 'AgentPhone',
  AgentPriceMonthlyFee: "AgentPriceMonthlyFee",
  AgentPriceAnnualFee: "AgentPriceAnnualFee",
  ApiRequest: "ApiRequest",
  TextToSpeech:"TextToSpeech",
  TextToSpeechStandard: "TextToSpeechStandard",
  TextToSpeechPremium: "TextToSpeechPremium",
  Computing: 'Computing',
  LiveAgent: 'LiveAgent',
  RemoteAgent: 'RemoteAgent'
};

export const ExtraServicePath = {
  CallRecording: 'call-recording',
  Transcription: 'transcription',
  LanguageDetection: 'language-detection',
  AutomaticSpeechRecognition: 'automatic-speech-recognition',
  CallRecordingPurchase: 'call-recording-purchase',
  TranscriptionPurchase: 'transcription-purchase',
  AutomaticSpeechRecognitionPurchase: 'automatic-speech-recognition-purchase',
  Conference: 'call-conference',
  AgentConference: 'agent-conference',
  AgentPhone: "agent-phone",
  ApiRequest: 'api-request',
  TextToSpeech: 'tts',
  Computing: 'computing',
  LiveAgent: 'live-agent',
  Remote: "remote-agent",
  PbxTrunks: "pbx-trunks"
}

export const ApiRequestChargeType={
  Never: "Never",
  Always: "Always",
  Accordingly:"Accordingly"
}

export const TtsType: { [key: string]: string } = {
  [ExtraService.TextToSpeechPremium]: 'Premium',
  [ExtraService.TextToSpeechStandard]: 'Standard'
}




