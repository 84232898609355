export const PaymentMethods = {
  Paypal: "PayPal",
  Card: "Card",
  Manual:'Manual'
}

export const PaymentStatus = {
  Succeeded: 'Success',
  Failed:'Failed'
}
 
