import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { DataSource } from '@angular/cdk/table';

@Component({
  selector: 'app-multi-channel-request-id',
  templateUrl: './multi-channel-request-id.component.html',
  styleUrl: './multi-channel-request-id.component.scss'
})
export class MultiChannelRequestIdComponent implements OnInit {

  public id: any = null;
  public callDirection: string = "";
  public telephoneNumber: string = "";
  public did: string = "";
  private requests: { [key: string]: string };
  public dataSource: RequesItemDataSource | null;
  public itemsSbj: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  public displayedColumns = ['channel', 'id']; 

  constructor(public dialogRef: MatDialogRef<MultiChannelRequestIdComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    if (this.data == null) {
      return;
    }

    this.requests = data.ids;
    this.callDirection = data.direction;
    this.id = data.id;
    this.did = data.did;
    this.telephoneNumber = data.phoneNumber;
  }

  ngOnInit(): void {

    this.dataSource = new RequesItemDataSource(this.itemsSbj);

    if (this.requests != null) {
      let items: any[] = [];

      for (let key in this.requests) {
        let value = this.requests[key];
        items.push({ channel: value, id: key });
      }

      this.itemsSbj.next(items);
    }
  }

}

export class RequesItemDataSource extends DataSource<any>{
  constructor(private subject: BehaviorSubject<any[]>) {
    super()
  }
  connect(): Observable<any[]> {
    return this.subject.asObservable();
  }
  disconnect() { };
}
