import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule as MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule as MatSlideToggleModule } from '@angular/material/slide-toggle';
import { RouterModule } from '@angular/router';
import { ApiAuthorizationModule } from '../../api-authorization/api-authorization.module';
import { SharedPaymentModule } from '../management/shared-payment/shared-payment.module';
import { ManagementHeaderComponent } from './management/management-header/management-header.component';
import { ManagementLayoutComponent } from './management/management-layout/management-layout.component';
import { PresentationFooterComponent } from './presentation/presentation-footer/presentation-footer.component';
import { PresentationHeaderComponent } from './presentation/presentation-header/presentation-header.component';
import { PresentationLayoutComponent } from './presentation/presentation-layout/presentation-layout.component';

@NgModule({
    declarations: [
        PresentationLayoutComponent,
        ManagementLayoutComponent,
        ManagementHeaderComponent,
        PresentationHeaderComponent,
        PresentationFooterComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        ApiAuthorizationModule,
        MatMenuModule,
        MatSlideToggleModule,
        SharedPaymentModule,
        ReactiveFormsModule
    ],
    exports: [
        PresentationLayoutComponent,
        ManagementLayoutComponent,
        ManagementHeaderComponent,
        PresentationHeaderComponent,
        PresentationFooterComponent
    ]
})
export class LayoutModule { }
