import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { ICountry } from '../core/interfaces/common/country.interface';

@Injectable()
export class MakePaymentFormService {

  public form: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder) {
    this.form = this.fb.group({
      amount: [null, [Validators.required,
        Validators.pattern("^[1-9][0-9]*$")]
      ],
      paymentMethod: [null, Validators.required],
      paymentType: ["card", Validators.required],
      totalAmount: [null],
      valueVAT: [null],
      description: [null],
      name: [null, Validators.required],
     // email: [null, Validators.required],
      country: [null, Validators.required],
      address: [null, Validators.required],
      city: [null, Validators.required],
      state:[null, Validators.required],
      postalCode: [null, Validators.required],
      acceptTerms: [false, Validators.required]
    });
  }

  getFormControl(property: string): UntypedFormControl {
    return this.form.get(property) as UntypedFormControl;
  }

  setMinAmount(value: number) {
    var control = this.form.get("amount") as UntypedFormControl;

    control.setValidators([Validators.required, Validators.min(value)]);
    control.updateValueAndValidity();
  }

  forbiddenNamesValidator(names: ICountry[]): ValidatorFn {
    //console.log("names",names)
    return (control: AbstractControl): { [key: string]: any } | null => {
      // below findIndex will check if control.value is equal to one of our options or not
      const index = names.findIndex(name => {
        return (new RegExp('\^' + name.name + '\$')).test(control.value);
      });
      //console.log(index, "index");
      return index < 0 ? { 'forbiddenCountries': { value: control.value } } : null;
    };
  }

  clearValidators(controlNames: string[]) {
    //console.log("clear validators");
    controlNames.map(value => {
      var control = this.form.get(value);
      control.clearValidators();
      control.updateValueAndValidity();
    })

  }

  setValidators(controlNames: string[], countries: ICountry[]) {
    //console.log("setValidators for:", controlNames);
    controlNames.map(value => {
      var control = this.form.get(value);
      if (value == "country") {
        control.setValidators([this.forbiddenNamesValidator(countries), Validators.required]);
      }
      else {
        control.setValidators(Validators.required);
      }

      control.updateValueAndValidity();
    })

  }

}
