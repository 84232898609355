<div class="items-table-container">
  <table mat-table [dataSource]="dataSource" class="full-width">

    <ng-container matColumnDef="duration">
      <th mat-header-cell *matHeaderCellDef>Duration</th>
      <td mat-cell *matCellDef="let element">{{element.duration | number : '1.3-3'}}</td>
    </ng-container>

    <ng-container matColumnDef="price">
      <th mat-header-cell *matHeaderCellDef>Price</th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="element.price==null && element.transcriptionPrice>0">{{element.transcriptionPrice | number : '1.3-5'}}</span>
        <span *ngIf="element.price > 0">{{element.price | number : '1.3-5'}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="storageCode">
      <th mat-header-cell *matHeaderCellDef>Storage Period</th>
      <td mat-cell *matCellDef="let element">{{element.storageLabel}}</td>
    </ng-container>

    <ng-container matColumnDef="transcription">
      <th mat-header-cell *matHeaderCellDef> Has Transcription</th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="element.transcription != null && element.transcription != ''">
          Yes  &nbsp; <i class="lni-comment" *ngIf="element.transcription!=null" matTooltip="{{element.transcription}}"></i>
        </span>
        <span *ngIf="!element.hasTranscription && ( element.transcription == null || element.transcription == '')">No</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="detect">
      <th mat-header-cell *matHeaderCellDef> Has language detection</th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="element.detectedLanguageCode != null && element.detectedLanguageCode != ''">
          Yes  &nbsp; <i class="lni-comment" *ngIf="element.detectedLanguageCode!=null" matTooltip="{{element.detectedLanguageCode}}"></i>
        </span>
        <span *ngIf="element.isLanguageDetection !=true && ( element.detectedLanguageCode == null || element.detectedLanguageCode == '')">No</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="play">
      <th mat-header-cell *matHeaderCellDef>Play</th>
      <td mat-cell *matCellDef="let element">
        <!--<button mat-icon-button (click)="playAudio(element.url)" matTooltip="Play" [disabled]="element.url==''">
        <span class="lni-play"></span>
      </button>-->

        <audio controls *ngIf="element.url!=null"
               controlsList="nofullscreen nodownload noremoteplayback noplaybackrate"
               preload='auto'
               class="play-recording" id="audio-{{element.id}}">
          <source src="{{element.url}}" type="audio/mpeg">
          Your browser does not support the audio element.
        </audio>
      </td>
    </ng-container>

    <!--<ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef>Actions</th>
    <td mat-cell *matCellDef="let element">
      <button mat-icon-button (click)="openMatDialog(element)" matTooltip=" View status history">
        <span class="lni-timer"></span>
      </button>
      <button mat-icon-button matTooltip="View channel behavior">
        <span class="lni-cloudnetwork"></span>
      </button>
    </td>
  </ng-container>-->

    <tr mat-header-row *matHeaderRowDef="displayedColumns sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>

</div>
