import { HttpClient, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ApiBaseUrl } from '../../constants/common.constants';
import { IAiAssistantLog } from '../../interfaces/ai/ai-assistant-log.interface';
import { AskAssistantRequest } from '../../interfaces/ai/ask-assistant-request';
import { AskAssistantResponse } from '../../interfaces/ai/ask-assistant-response';
import { IAssistantEndpointHit } from '../../interfaces/ai/assistant-end-point-hit.interface';
import { IAiAssistantInstruction, IAiAssistantModel, IAssistant, IAssistantFileSizeInfo } from '../../interfaces/ai/assistant.interface';
import { IAutomaticTextTranslation } from '../../interfaces/ai/automatic-text-translation';
import { IBotDetectLanguageBilling } from '../../interfaces/ai/bot-detect-language-billing.interface';
import { IBotEntity, IBotSubEntity } from '../../interfaces/ai/bot-entity.interface';
import { IBotEnvironment } from '../../interfaces/ai/bot-environment.interface';
import { IBotIntentEntity } from '../../interfaces/ai/bot-intent-entity.interface';
import { IBotIntentUtterance } from '../../interfaces/ai/bot-intent-utterance.interface';
import { IBotIntent } from '../../interfaces/ai/bot-intent.interface';
import { IBotLanguage } from '../../interfaces/ai/bot-language.interface';
import { IBotPrebuiltEntity } from '../../interfaces/ai/bot-prebuilt-entity.interface';
import { IBotPrediction } from '../../interfaces/ai/bot-prediction.interface';
import { IBotPublishedVersion } from '../../interfaces/ai/bot-published-version.interface';
import { IBotTestingRequest } from '../../interfaces/ai/bot-testing-request.interface';
import { IBotTimezone } from '../../interfaces/ai/bot-timezone.interface';
import { IBotTrainingBilling } from '../../interfaces/ai/bot-training-billing.interface';
import { IBot } from '../../interfaces/ai/bot.interface';
import { IBotEndpointHit } from '../../interfaces/ai/end-point-hit.interface';
import { IPredictionResult } from '../../interfaces/ai/prediction-result.interface';
import { IRephraseInstructions } from '../../interfaces/ai/rephrase-instructions.interface';
import { IActivityRequest } from '../../interfaces/common/activity-request.interface';
import { CommonRequestParams } from '../../interfaces/common/common-request-params';
import { IExportFileRequestParams } from '../../interfaces/common/export-file-request-params.interface';
import { IResponseCall } from '../../interfaces/common/response-call.interface';
import { ICustomer } from '../../interfaces/customer/customer';
import { DataSourceResponse } from '../../interfaces/data/datasourceresponse.interface';
import { GetStructResponse } from '../../interfaces/data/get-struct-response.interface';

@Injectable()
export class AiManagementService {

  private _baseUrl: string = `${ApiBaseUrl}aimanagement`;

  constructor(private httpClient: HttpClient) { }

  //bots
  getBots(request: CommonRequestParams): Observable<DataSourceResponse<IBot>> {
    return this.httpClient.post<DataSourceResponse<IBot>>(`${this._baseUrl}/readBots`, request);
  }

  getAllCustomerBots(customerCode: string = ''): Observable<IBot[]> {
    return this.httpClient.get<IBot[]>(`${this._baseUrl}/readBots/${customerCode}`);
  }

  getBot(botId: string): Observable<IBot> {
    return this.httpClient.get<IBot>(`${this._baseUrl}/bot/${botId}`);
  }

  getBotByName(botUniqueIdentifier: string): Observable<IBot> {
    return this.httpClient.get<IBot>(`${this._baseUrl}/bot-name/${botUniqueIdentifier}`);
  }

  getLanguages(): Observable<IBotLanguage[]> {
    return this.httpClient.get<IBotLanguage[]>(`${this._baseUrl}/language`);
  }

  getBotDefaultLanguage(botId: any): Observable<IBotLanguage> {
    return this.httpClient.get<IBotLanguage>(`${this._baseUrl}/default-language/${botId}`);
  }

  createBot(bot: IBot): Observable<IResponseCall> {
    return this.httpClient.post<IResponseCall>(`${this._baseUrl}/createBot`, bot);
  }

  cloneBot(bot: IBot, botId: any): Observable<IResponseCall> {
    return this.httpClient.post<IResponseCall>(`${this._baseUrl}/cloneBot/${botId}`, bot);
  }

  updateBot(bot: IBot): Observable<IResponseCall> {
    return this.httpClient.post<IResponseCall>(`${this._baseUrl}/updateBot`, bot);
  }

  deleteBot(bot: IBot): Observable<IResponseCall> {
    return this.httpClient.post<IResponseCall>(`${this._baseUrl}/deleteBot`, bot);
  }

  getBotEnvironments(botId: string): Observable<IBotEnvironment[]> {
    return this.httpClient.get<IBotEnvironment[]>(`${this._baseUrl}/environments/${botId}`);
  }

  getBotPublishedEnvironments(botId: string): Observable<string[]> {
    return this.httpClient.get<string[]>(`${this._baseUrl}/published-environments/${botId}`);
  }

  publishBot(bot: IBot): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this._baseUrl}/publishBot`, bot);
  }

  trainBot(bot: IBot): Observable<IResponseCall> {
    return this.httpClient.post<IResponseCall>(`${this._baseUrl}/trainBot`, bot);
  }

  testBot(request: IBotTestingRequest): Observable<IPredictionResult> {
    return this.httpClient.post<IPredictionResult>(`${this._baseUrl}/testBot`, request);
  }
  getBotPublishedVersions(request: CommonRequestParams, botId): Observable<DataSourceResponse<IBotPublishedVersion>> {
    return this.httpClient.post<DataSourceResponse<IBotPublishedVersion>>(`${this._baseUrl}/published-versions/${botId}`, request);
  }

  getBotAllPublishedVersions(botId): Observable<IBotPublishedVersion[]> {
    return this.httpClient.get<IBotPublishedVersion[]>(`${this._baseUrl}/published-versions/${botId}`);
  }

  getBotTimeZone(): Observable<IBotTimezone[]> {
    return this.httpClient.get<IBotTimezone[]>(`${this._baseUrl}/timezones`);
  }

  //intents
  getBotIntents(request: CommonRequestParams, botId): Observable<DataSourceResponse<IBotIntent>> {
    return this.httpClient.post<DataSourceResponse<IBotIntent>>(`${this._baseUrl}/read-intents/${botId}`, request);
  }

  getAllBotIntents(botId): Observable<IBotIntent[]> {
    return this.httpClient.get<IBotIntent[]>(`${this._baseUrl}/read-intents/${botId}`);
  }

  createBotIntent(botIntent: IBotIntent): Observable<GetStructResponse<any>> {
    return this.httpClient.post<GetStructResponse<any>>(`${this._baseUrl}/createIntent`, botIntent);
  }

  updateBotIntent(botIntent: IBotIntent): Observable<IResponseCall> {
    return this.httpClient.post<IResponseCall>(`${this._baseUrl}/updateIntent`, botIntent);
  }

  deleteIntent(botIntent: IBotIntent): Observable<IResponseCall> {
    return this.httpClient.post<IResponseCall>(`${this._baseUrl}/deleteIntent`, botIntent);
  }

  getBotIntentUtterances(request: CommonRequestParams, botIntendId: any): Observable<DataSourceResponse<IBotIntentUtterance>> {
    return this.httpClient.post<DataSourceResponse<IBotIntentUtterance>>(`${this._baseUrl}/read-phareses/${botIntendId}`, request);
  }

  saveBotIntentUtterances(list: IBotIntentUtterance[], botIntendId: any, botid: any): Observable<GetStructResponse<boolean>> {
    return this.httpClient.post<GetStructResponse<boolean>>(`${this._baseUrl}/save-phareses/${botIntendId}/${botid}`, list);
  }

  checkIfSubentityIsUsed(subEntity: IBotSubEntity, botId: any) {
    return this.httpClient.post<boolean>(`${this._baseUrl}/subentity-usage/${botId}`, subEntity)
  }

  //predictions
  getBotPredictions(request: CommonRequestParams, botId): Observable<DataSourceResponse<IBotPrediction>> {
    return this.httpClient.post<DataSourceResponse<IBotPrediction>>(`${this._baseUrl}/read-predictions/${botId}`, request);
  }

  savePredictionForIntent(botPrediction: IBotPrediction): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this._baseUrl}/savePrediction`, botPrediction);
  }

  deletePrediction(botPrediction: IBotPrediction): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this._baseUrl}/deletePrediction`, botPrediction);
  }

  //entities
  getEntities(request: CommonRequestParams, botId): Observable<DataSourceResponse<IBotEntity>> {
    return this.httpClient.post<DataSourceResponse<IBotEntity>>(`${this._baseUrl}/read-entities/${botId}`, request);
  }

  getAllEntities(botId: any, name: string): Observable<IBotEntity[]> {
    return this.httpClient.get<IBotEntity[]>(`${this._baseUrl}/read-all-entities/${botId}/${name}`);
  }

  getPrebuiltEntities(botId: any): Observable<IBotPrebuiltEntity[]> {
    return this.httpClient.get<IBotPrebuiltEntity[]>(`${this._baseUrl}/prebuilt-entities/${botId}`);
  }

  createEntity(entity: IBotEntity): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this._baseUrl}/create-entity`, entity);
  }

  updateEntity(entity: IBotEntity): Observable<GetStructResponse<boolean>> {
    return this.httpClient.post<GetStructResponse<boolean>>(`${this._baseUrl}/update-entity`, entity);
  }

  createEntities(entities: IBotEntity[]): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this._baseUrl}/create-entities`, entities);
  }

  deleteEntity(entity: IBotEntity): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this._baseUrl}/delete-entity`, entity);
  }

  getEntity(id: any, botId: any): Observable<IBotEntity> {
    return this.httpClient.get<IBotEntity>(`${this._baseUrl}/entity/${id}/${botId}`);
  }

  getEntityIntents(id: any, botId: any, request: CommonRequestParams): Observable<DataSourceResponse<IBotIntentEntity>> {
    return this.httpClient.post<DataSourceResponse<IBotIntentEntity>>(`${this._baseUrl}/entity-intents/${id}/${botId}`, request);
  }

  getAllEntityIntents(id: any, botId: any): Observable<IBotIntentEntity[]> {
    return this.httpClient.get<IBotIntentEntity[]>(`${this._baseUrl}/all-entity-intents/${id}/${botId}`);
  }

  //activity
  getBotEndpointHits(request: IActivityRequest): Observable<DataSourceResponse<IBotEndpointHit>> {
    return this.httpClient.post<DataSourceResponse<IBotEndpointHit>>(`${this._baseUrl}/activity`, request);
  }

  getActivityAggregates(request: IActivityRequest): Observable<any> {
    return this.httpClient.post<any>(`${this._baseUrl}/activityAggregates`, request);
  }

  exportBotEndpointHits(request: IExportFileRequestParams): Observable<IResponseCall> {
    return this.httpClient.post<IResponseCall>(`${this._baseUrl}/exportActivity`, request);
  }

  //rephrase instructions
  //!!!if this method is changed with pagination search all references and make another method for all to call
  getAiRephraseInstructionsEntities(): Observable<DataSourceResponse<IRephraseInstructions>> {
    return this.httpClient.get<DataSourceResponse<IRephraseInstructions>>(`${this._baseUrl}/rephrase-instructions`);
  }

  saveAiRephraseInstructionsEntity(rephraseInstructions: IRephraseInstructions): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this._baseUrl}/save-rephrase-instructions-entity`, rephraseInstructions);
  }

  deleteAiRephraseInstructionsEntity(rephraseInstructions: IRephraseInstructions): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this._baseUrl}/delete-rephrase-instructions-entity`, rephraseInstructions);
  }

  updateAiRephraseInstructionsEntity(rephraseInstructions: IRephraseInstructions): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this._baseUrl}/update-rephrase-instructions-entity`, rephraseInstructions);
  }

  //assistants
  getAssistants(request: CommonRequestParams): Observable<DataSourceResponse<IAssistant>> {
    return this.httpClient.post<DataSourceResponse<IAssistant>>(`${this._baseUrl}/read-assistants`, request);
  }

  getAssistantActivity(request: IActivityRequest): Observable<DataSourceResponse<IAssistantEndpointHit>> {
    return this.httpClient.post<DataSourceResponse<IAssistantEndpointHit>>(`${this._baseUrl}/assistant-activity`, request);
  }

  getAssistantActivityAggregates(request: IActivityRequest): Observable<any> {
    return this.httpClient.post<any>(`${this._baseUrl}/assistant-activityAggregates`, request);
  }

  exportAssistantEndpointHits(request: IExportFileRequestParams): Observable<IResponseCall> {
    return this.httpClient.post<IResponseCall>(`${this._baseUrl}/assistant-exportActivity`, request);
  }

  getAssistant(id: any): Observable<IAssistant> {
    return this.httpClient.get<IAssistant>(`${this._baseUrl}/assistant/${id}`);
  }

  getFileSizeInfo(): Observable<IAssistantFileSizeInfo> {
    return this.httpClient.get<IAssistantFileSizeInfo>(`${this._baseUrl}/fileSize-info`);
  }

  getAssistantModels(): Observable<IAiAssistantModel[]> {
    return this.httpClient.get<IAiAssistantModel[]>(`${this._baseUrl}/assistant-models`);
  }

  getAssistantInstructions(type: string): Observable<IAiAssistantInstruction[]> {
    return this.httpClient.get<IAiAssistantInstruction[]>(`${this._baseUrl}/assistant-instructions/${type}`);
  }

  createAssistant(data: any): Observable<GetStructResponse<IAssistant>> {
    return this.httpClient.post<GetStructResponse<IAssistant>>(`${this._baseUrl}/create-assistant`, data);
  }

  updateAssistant(data: any): Observable<GetStructResponse<boolean>> {
    return this.httpClient.post<GetStructResponse<boolean>>(`${this._baseUrl}/update-assistant`, data);
  }

  uploadAssistantFiles(data: any) {
    return this.httpClient.post<any>(`${this._baseUrl}/upload-assistant-files`, data, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map((event) => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            const progress = Math.round(100 * event.loaded / event.total);
            return { status: 'progress', message: progress };

          case HttpEventType.Response:
            return event.body;

          case HttpEventType.ResponseHeader: {
            //add also this type, in case of large files, type 4 (HttpEventType.Response) is received after a longer period
            return { success: event.ok };
          }

          default:
            return `Unhandled event: ${event.type}`;
        }
      })
    );
  }

  deleteAssistant(assistant: IAssistant): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this._baseUrl}/delete-assistant`, assistant);
  }

  cancelDeletion(assistant: IAssistant): Observable<GetStructResponse<boolean>> {
    return this.httpClient.post<GetStructResponse<boolean>>(`${this._baseUrl}/cancel-delete-assistant`, assistant);
  }

  reactivateAssistant(assistant: IAssistant): Observable<GetStructResponse<boolean>> {
    return this.httpClient.post<GetStructResponse<boolean>>(`${this._baseUrl}/reactivate-assistant`, assistant);
  }

  getAllAssistants(customerCode: string = ""): Observable<IAssistant[]> {
    return this.httpClient.get<IAssistant[]>(`${this._baseUrl}/read-assistants/${customerCode}`);
  }

  getAllCustomerAssistants(): Observable<ICustomer[]> {
    return this.httpClient.get<ICustomer[]>(`${this._baseUrl}/read-customers-assistant`);
  }

  updateAssistantInstructions(assistant: IAssistant): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this._baseUrl}/update-assistant-instructions`, assistant);
  }

  getDefaultAssistantInstructions(): Observable<IAiAssistantInstruction> {
    return this.httpClient.get<IAiAssistantInstruction>(`${this._baseUrl}/default-instructions`);
  }

  saveDefaultAssistantInstructions(instructions:IAiAssistantInstruction): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this._baseUrl}/default-instructions`, instructions);
  }

  //ask assistant
  askAssistant(request: AskAssistantRequest): Observable<AskAssistantResponse> {
    return this.httpClient.post<AskAssistantResponse>(`${this._baseUrl}/test-assistant`, request);
  }

  startSession(assistantId: string, assistantType: string):Observable<GetStructResponse<string>> {
    return this.httpClient.get<GetStructResponse<string>>(`${this._baseUrl}/start-assistant-thread/${assistantId}/${assistantType}`);
  }

  deleteSession(threadId: string, assistantType: string, assistantId: string): Observable<GetStructResponse<boolean>> {
    return this.httpClient.get<GetStructResponse<boolean>>(`${this._baseUrl}/delete-assistant-thread/${threadId}/${assistantId}/${assistantType}`);
  }

  getAssistantsBillingDetails(request: CommonRequestParams): Observable<DataSourceResponse<IAiAssistantLog>> {
    return this.httpClient.post<DataSourceResponse<IAiAssistantLog>>(`${this._baseUrl}/billing-info-assistant`, request);
  }

  getTrainingBillingDetails(request: CommonRequestParams, ownTransactions: boolean): Observable<DataSourceResponse<IBotTrainingBilling>> {
    return this.httpClient.post<DataSourceResponse<IBotTrainingBilling>>(`${this._baseUrl}/training-billing-details/${ownTransactions}`, request);
  }

  getDetectLanguageBillingDetails(request: IActivityRequest, ownTransactions: boolean): Observable<DataSourceResponse<IBotDetectLanguageBilling>> {
    return this.httpClient.post<DataSourceResponse<IBotDetectLanguageBilling>>(`${this._baseUrl}/detect-language-billing-details/${ownTransactions}`, request);
  }

  getTranslateBillingDetails(request: IActivityRequest, ownTransactions: boolean): Observable<DataSourceResponse<IAutomaticTextTranslation>> {
    return this.httpClient.post<DataSourceResponse<IAutomaticTextTranslation>>(`${this._baseUrl}/translate-billing-details/${ownTransactions}`, request);
  }

}
