import { HttpClient, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiBaseUrl } from '../../constants/common.constants';
import { IAgent } from '../../interfaces/agent-conference/agent';
import { CommonRequestParams } from '../../interfaces/common/common-request-params';
import { IResponseCall } from '../../interfaces/common/response-call.interface';
import { IStructResponseCall } from '../../interfaces/common/struct-response-call.interface';
import { DataSourceResponse } from '../../interfaces/data/datasourceresponse.interface';
import { ILiveAgentChat } from '../../interfaces/live-agent/live-agent-chat';
import { ILiveAgentCustomSettings } from '../../interfaces/live-agent/live-agent-custom-settings';
import { ILiveAgentEntry } from '../../interfaces/live-agent/live-agent-entry';
import { ILiveAgentGlobalSettings } from '../../interfaces/live-agent/live-agent-global-settings';
import { ILiveAgentPriceList } from '../../interfaces/live-agent/live-agent-price-list';
import { ILiveAgentQueue } from '../../interfaces/live-agent/live-agent-queue';
import { ILiveAgentTask } from '../../interfaces/live-agent/live-agent-task';
import { IConversationItem } from '../../interfaces/smart-dialog/conversation-item.interface';
import { ILiveAgentInteraction } from '../../interfaces/live-agent/live-agent-interaction';
import { map } from 'rxjs/operators';

@Injectable()


export class LiveAgentService {

  private _baseUrl: string = `${ApiBaseUrl}liveagent`;

  constructor(private httpClient: HttpClient) { }

  getLiveAgentPricelist(): Observable<ILiveAgentPriceList[]> {
    return this.httpClient.get<ILiveAgentPriceList[]>(`${this._baseUrl}`);
  }

  getCustomerLiveAgentPricelist(): Observable<ILiveAgentPriceList[]> {
    return this.httpClient.get<ILiveAgentPriceList[]>(`${this._baseUrl}/customer-pricelist`);
  }

  saveLiveAgentPriceList(priceListDto: ILiveAgentPriceList): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this._baseUrl}`, priceListDto);
  }

  //-----live agent queues-----
  getLiveAgentQueues(request: CommonRequestParams): Observable<DataSourceResponse<ILiveAgentQueue>> {
    return this.httpClient.post<DataSourceResponse<ILiveAgentQueue>>(`${this._baseUrl}/group`, request);
  }

  getAllLiveAgentQueues(): Observable<ILiveAgentQueue[]> {
    return this.httpClient.get<ILiveAgentQueue[]>(`${this._baseUrl}/group-all`);
  }

  createLiveAgentQueue(item: ILiveAgentQueue): Observable<IResponseCall> {
    return this.httpClient.post<IResponseCall>(`${this._baseUrl}/group-create`, item);
  }

  updateLiveAgentQueue(item: ILiveAgentQueue): Observable<IResponseCall> {
    return this.httpClient.post<IResponseCall>(`${this._baseUrl}/group-update`, item);
  }

  deleteLiveAgentQueue(item: ILiveAgentQueue): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this._baseUrl}/group-delete`, item);
  }

  getLiveAgentGlobalSettings(): Observable<ILiveAgentGlobalSettings> {
    return this.httpClient.get<ILiveAgentGlobalSettings>(`${this._baseUrl}/global-settings`);
  }

  updateLiveAgentGlobalSettings(settings: ILiveAgentGlobalSettings): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this._baseUrl}/global-settings`, settings);
  }

  //--------activitylog---------
  insertActivityLog(type, channel): Observable<boolean> {
    return this.httpClient.get<boolean>(`${this._baseUrl}/activity-log/${type}/${channel}`);
  }

  //-------agent entry---------
  getAgentAvailability(type): Observable<any> {
    return this.httpClient.get<any>(`${this._baseUrl}/live-agent-availabilty/${type}`);
  }

  insertAgentEntry(type): Observable<ILiveAgentEntry> {
    return this.httpClient.get<ILiveAgentEntry>(`${this._baseUrl}/add-agent-entry/${type}`);
  }

  endLiveAgentEntry(entryId: any): Observable<boolean> {
    return this.httpClient.get<boolean>(`${this._baseUrl}/end-agent-entry/${entryId}`);
  }


 //------agent custom settings------

  getLiveAgentCustomSettings(): Observable<ILiveAgentCustomSettings> {
    return this.httpClient.get<ILiveAgentCustomSettings>(`${this._baseUrl}/agent-settings`);
  }

  updateLiveAgentCustomSettings(settings: ILiveAgentCustomSettings): Observable<ILiveAgentCustomSettings> {
    return this.httpClient.post<ILiveAgentCustomSettings>(`${this._baseUrl}/agent-settings`, settings);
  }


  //---------agent new tasks/chat/groups-----//

  getNewTasks(agentId: any): Observable<ILiveAgentTask[]> {
    return this.httpClient.get<ILiveAgentTask[]>(`${this._baseUrl}/new-task/${agentId}`);
  }

  getCurrentChats(agentId: any): Observable<ILiveAgentInteraction[]> {
    return this.httpClient.get<ILiveAgentInteraction[]>(`${this._baseUrl}/chat/${agentId}`);
  }

  getCurrentChat(agentId: any, channel: string, entityId: any): Observable<ILiveAgentInteraction> {
    return this.httpClient.get<ILiveAgentInteraction>(`${this._baseUrl}/chat/${agentId}/${channel}/${entityId}`);
  }

  getAgentQueues(): Observable<ILiveAgentQueue[]> {
    return this.httpClient.get<ILiveAgentQueue[]>(`${this._baseUrl}/agent-groups`);
  }

  declineTask(taskId:any, agentId: any): Observable<IResponseCall> {
    return this.httpClient.get<IResponseCall>(`${this._baseUrl}/decline-task/${taskId}/${agentId}`);
  }

  completeTask(taskId: any, agentId: any): Observable<boolean> {
    return this.httpClient.get<boolean>(`${this._baseUrl}/close-task/${taskId}/${agentId}`);
  }

  saveAgentInteractionLog(entityId: string, message: string, agentId: any, agentNickname: string, type: string, channel:string, channelDirection:string, taskId:any, contact:string, contactName:string, source:string):Observable<boolean> {
    let log: IConversationItem = {
      agentId: agentId,
      type: type,
      text: message,
      channel: channel,
      channelDirection: channelDirection,
      agentNickname: agentNickname,
      contact: contact,
      contactName: contactName,
      source:source
    };

    return this.httpClient.post<boolean>(`${this._baseUrl}/interaction-log/${entityId}/${taskId}`, log);
  }

  getTaskAvailableAgents(taskId: any, agentId: any): Observable<IAgent[]> {
    return this.httpClient.get<IAgent[]>(`${this._baseUrl}/task-agents/${taskId}/${agentId}`);
  }

  inviteAgent(taskId: any, agentId: any, inviteAgentId: any): Observable<any> {
    return this.httpClient.get<any>(`${this._baseUrl}/invite/${taskId}/${agentId}/${inviteAgentId}`);
  }

  leaveInvitation(taskId: any, agentId: any, inviteAgentId: any): Observable<any> {
    return this.httpClient.get<any>(`${this._baseUrl}/leave-invitation/${taskId}/${agentId}/${inviteAgentId}`);
  }

  transferTask(taskId: any, agentId: any, transferAgentId: any): Observable<any> {
    return this.httpClient.get<any>(`${this._baseUrl}/transfer/${taskId}/${agentId}/${transferAgentId}`);
  }

  acceptTask(taskId: any, agentId: any): Observable<IStructResponseCall> {
    return this.httpClient.get<IStructResponseCall>(`${this._baseUrl}/accept-task/${taskId}/${agentId}`);
  }

  sendTypingEvent(entityId: string, agentId: any, isTyping: boolean, otherAgentId: any = null): Observable<boolean> {
    return this.httpClient.get<boolean>(`${this._baseUrl}/typing/${entityId}/${agentId}/${isTyping}/${otherAgentId}`);
  }

  markChatAsRead(agentId: any, channel: string, entityId: any): Observable<boolean> {
    return this.httpClient.get<boolean>(`${this._baseUrl}/read-chat/${agentId}/${channel}/${entityId}`);
  }


 //------agent document------

  uploadDocument(data: any) {
    return this.httpClient.post<any>(`${this._baseUrl}/document-upload`, data, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map((event) => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            const progress = Math.round(100 * event.loaded / event.total);
            return { status: 'progress', message: progress };

          case HttpEventType.Response:
            return event.body;

          default:
            return `Unhandled event: ${event.type}`;
        }
      })
    );
  }

  getDownloadUrl(userToken: string, fileName: string): string {
    return `${this._baseUrl}/document-download/${fileName}?userToken=${userToken}`;
  }
}
