<h3>Call price details</h3>

<span *ngIf="request!=null"><b>RequestId: </b>{{request.id}}</span>

<div class="container mt-3" *ngIf='callServices.length > 0'>
  <table mat-table [dataSource]="callServiceDataSource" class="full-width">

    <ng-container matColumnDef="serviceName">
      <th mat-header-cell *matHeaderCellDef>Service Name</th>
      <td mat-cell *matCellDef="let element"><b>{{element.serviceName}}</b></td>
    </ng-container>

    <ng-container matColumnDef="duration">
      <th mat-header-cell *matHeaderCellDef>Duration</th>
      <td mat-cell *matCellDef="let element">{{element.duration  | number : '1.1-2'}}</td>
    </ng-container>

    <ng-container matColumnDef="pricePerInterval">
      <th mat-header-cell *matHeaderCellDef>Price / interval</th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="element.totalPrice!=null && element.interval!=null && element.serviceName != 'Computing'">{{element.pricePerInterval | number : '1.3-5'}} € / {{element.interval}} sec</span>
        <span *ngIf="element.serviceName == 'Computing'">{{element.pricePerInterval | number : '1.3-5'}} € / {{element.interval}} node</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="numberOfIntervals">
      <th mat-header-cell *matHeaderCellDef>No. intervals</th>
      <td mat-cell *matCellDef="let element">
        {{element.numberOfIntervals}}
      </td>
    </ng-container>

    <ng-container matColumnDef="totalPrice">
      <th mat-header-cell *matHeaderCellDef>Total price</th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf='element.totalPrice!=null'>{{element.totalPrice | number : '1.3-5' }} €</span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="callServiceDisplayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: callServiceDisplayedColumns;"></tr>

  </table>
</div>



<div class="container mt-3" *ngIf='callTTSList.length>0'>


  <table mat-table [dataSource]="callTTSDataSource" class="full-width">

    <ng-container matColumnDef="tts">
      <th mat-header-cell *matHeaderCellDef><b>TEXT-TO-SPEECH</b></th>
      <td mat-cell *matCellDef="let element"><b>{{element.serviceName}}</b></td>
    </ng-container>


    <ng-container matColumnDef="pricePerCharacters">
      <th mat-header-cell *matHeaderCellDef>Price / characters</th>
      <td mat-cell *matCellDef="let element">
        {{element.pricePerInterval | number : '1.3-5'}} € / {{element.interval}}
      </td>
    </ng-container>

    <ng-container matColumnDef="numberOfCharacters">
      <th mat-header-cell *matHeaderCellDef>No. characters</th>
      <td mat-cell *matCellDef="let element">
        {{element.numberOfIntervals}}
      </td>
    </ng-container>

    <ng-container matColumnDef="totalPrice">
      <th mat-header-cell *matHeaderCellDef>Total price</th>
      <td mat-cell *matCellDef="let element">
        {{element.totalPrice | number : '1.3-5' }} €
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="callTTSDisplayedColumns "></tr>
    <tr mat-row *matRowDef="let row; columns: callTTSDisplayedColumns;"></tr>

  </table>



</div>


<div class="container mt-3" *ngIf="callRecordings.length > 0">
  <table mat-table [dataSource]="callRecordingsDataSource" class="full-width">

    <ng-container matColumnDef="recordings">
      <th mat-header-cell *matHeaderCellDef><b>RECORDINGS</b></th>
      <td mat-cell *matCellDef="let element"><b>{{element.name}}</b></td>
    </ng-container>

    <ng-container matColumnDef="nodeKey">
      <th mat-header-cell *matHeaderCellDef>Node key</th>
      <td mat-cell *matCellDef="let element">{{element.nodeKey}}</td>
    </ng-container>

    <ng-container matColumnDef="recDuration">
      <th mat-header-cell *matHeaderCellDef>Duration (sec.)</th>
      <td mat-cell *matCellDef="let element">
        <span>{{element.duration | number : '1.1-2'}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="priceDetails">
      <th mat-header-cell *matHeaderCellDef>PriceDetails</th>
      <td mat-cell *matCellDef="let element">

        <div class="secondary-underline">
          <div class="row secondary-underline text-header">
            <div class="col-sm-3">
              Service name
            </div>

            <div class="col-sm-3">
              Price / interval
            </div>

            <div class="col-sm-3">
              No. intervals
            </div>

            <div class="col-sm-3">
              Total price
            </div>
          </div>

          <div class="row  text-header secondary-underline" *ngFor="let service of element.services">
            <div class="col-sm-3">
              <b>{{service.serviceName}}</b>
            </div>

            <div class="col-sm-3">
              {{service.pricePerInterval | number : '1.3-5'}} € / {{service.interval}} sec
            </div>

            <div class="col-sm-3">
              {{service.numberOfIntervals}}
            </div>

            <div class="col-sm-3">
              {{service.totalPrice | number : '1.3-5' }} €
            </div>
          </div>

        </div>
        <div class="w-100 text-right"><b>Total price for {{element.name}}: {{element.totalPrice | number : '1.3-5'}} € </b></div>


      </td>
    </ng-container>



    <tr mat-header-row *matHeaderRowDef="callRecordingsDisplayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: callRecordingsDisplayedColumns;"></tr>

  </table>
</div>

<div class="container mt-3" *ngIf="callAIList.length > 0">
  <table mat-table [dataSource]="callAIDataSource" class="full-width">

    <ng-container matColumnDef="ai">
      <th mat-header-cell *matHeaderCellDef class="ai-column-name">AI</th>
      <td mat-cell *matCellDef="let element"><b>{{element.name}}</b></td>
    </ng-container>

    <ng-container matColumnDef="interval">
      <th mat-header-cell *matHeaderCellDef class="ai-column">Interval</th>
      <td mat-cell *matCellDef="let element">
        {{element.interval}}
        <br />
        <span *ngIf="element.name=='Rephrasing'">tokens</span>
        <span *ngIf="element.name!='Rephrasing'">chars</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="pricePerIntervalAI">
      <th mat-header-cell *matHeaderCellDef class="ai-column">Price / <br />Interval</th>
      <td mat-cell *matCellDef="let element">
        <span>{{element.pricePerInterval | number : '1.3-5'}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="priceDetails">
      <th mat-header-cell *matHeaderCellDef>Price Details</th>
      <td mat-cell *matCellDef="let element">

        <div class="secondary-underline">
          <div class="row secondary-underline text-header">
            <div class="col-sm-6">
              Text
            </div>

            <div class="col-sm-2">
              Length
            </div>

            <div class="col-sm-2">
              No. intervals
            </div>

            <div class="col-sm-2">
              Total price
            </div>
          </div>

          <div class="row  text-header" *ngFor="let service of element.services">
            <div class="col-sm-6">
              <small>{{service.serviceName}}</small>
            </div>

            <div class="col-sm-2">
              {{service.duration}}
              <span *ngIf="element.name=='Rephrasing'">tokens</span>
              <span *ngIf="element.name!='Rephrasing'">chars</span>
            </div>

            <div class="col-sm-2">
              {{service.numberOfIntervals}}
            </div>

            <div class="col-sm-2">
              {{service.totalPrice | number : '1.3-5' }} €
            </div>
          </div>

        </div>

        <div class="w-100 text-right">
          <b>Total price for {{element.name}}: {{element.totalPrice | number : '1.3-5'}} € </b>
        </div>

      </td>
    </ng-container>



    <tr mat-header-row *matHeaderRowDef="callAIDisplayedColumns "></tr>
    <tr mat-row *matRowDef="let row; columns: callAIDisplayedColumns;"></tr>

  </table>
</div>

<div class="container mt-3" *ngIf="callAIAskAssistantBehaviorSbj.value.length > 0">
  <table mat-table [dataSource]="callAIAskAssistantDataSource" class="full-width">

    <ng-container matColumnDef="model">
      <th mat-header-cell *matHeaderCellDef class="ai-column-name">Ask AI Assistant</th>
      <td mat-cell *matCellDef="let element"><b>{{element.name}}</b></td>
    </ng-container>

    <ng-container matColumnDef="priceDetails">
      <th mat-header-cell *matHeaderCellDef>Price Details</th>
      <td mat-cell *matCellDef="let element">

        <div class="secondary-underline">
          <div class="row secondary-underline text-header">
            <div class="col-sm-6">
              Type
            </div>

            <div class="col-sm-2">
              Tokens
            </div>

            <div class="col-sm-2">
              No. intervals
            </div>

            <div class="col-sm-2">
              Total price
            </div>
          </div>

          <div class="row  text-header" *ngFor="let service of element.services">
            <div class="col-sm-6">
              <small>{{service.serviceName}}</small>
            </div>

            <div class="col-sm-2">
              {{service.duration}} tokens
            </div>

            <div class="col-sm-2">
              {{service.numberOfIntervals}}
            </div>

            <div class="col-sm-2">
              {{service.totalPrice | number : '1.3-5' }} €
            </div>
          </div>

        </div>

        <div class="w-100 text-right">
          <b>Total price for {{element.name}}: {{element.totalPrice | number : '1.3-5'}} € </b>
        </div>

      </td>
    </ng-container>



    <tr mat-header-row *matHeaderRowDef="callAssistantDisplayedColumns "></tr>
    <tr mat-row *matRowDef="let row; columns: callAssistantDisplayedColumns;"></tr>

  </table>
</div>

<div class="container mt-3" *ngIf="callAIGenerativeBehaviorSbj.value.length > 0">
  <table mat-table [dataSource]="callAIGenerativeDataSource" class="full-width">

    <ng-container matColumnDef="model">
      <th mat-header-cell *matHeaderCellDef class="ai-column-name">Ask Generative AI</th>
      <td mat-cell *matCellDef="let element"><b>{{element.name}}</b></td>
    </ng-container>

    <ng-container matColumnDef="priceDetails">
      <th mat-header-cell *matHeaderCellDef>Price Details</th>
      <td mat-cell *matCellDef="let element">

        <div class="secondary-underline">
          <div class="row secondary-underline text-header">
            <div class="col-sm-6">
              Model
            </div>

            <div class="col-sm-2">
              Tokens
            </div>

            <div class="col-sm-2">
              No. intervals
            </div>

            <div class="col-sm-2">
              Total price
            </div>
          </div>

          <div class="row  text-header" *ngFor="let service of element.services">
            <div class="col-sm-6">
              <small>{{service.serviceName}}</small>
            </div>

            <div class="col-sm-2">
              {{service.duration}} tokens
            </div>

            <div class="col-sm-2">
              {{service.numberOfIntervals}}
            </div>

            <div class="col-sm-2">
              {{service.totalPrice | number : '1.3-5' }} €
            </div>
          </div>

        </div>

        <div class="w-100 text-right">
          <b>Total price for {{element.name}}: {{element.totalPrice | number : '1.3-5'}} € </b>
        </div>

      </td>
    </ng-container>



    <tr mat-header-row *matHeaderRowDef="callAssistantDisplayedColumns "></tr>
    <tr mat-row *matRowDef="let row; columns: callAssistantDisplayedColumns;"></tr>

  </table>
</div>

<div class="w-100 text-right primary-color mt-2"><b class="mr-3">TOTAL PRICE:  {{request.price | number : '1.3-5'}} € </b></div>
