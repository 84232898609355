import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiBaseUrl } from '../../constants/common.constants';
import { IActivityRequest } from '../../interfaces/common/activity-request.interface';
import { CommonRequestParams } from '../../interfaces/common/common-request-params';
import { IExportFileRequestParams } from '../../interfaces/common/export-file-request-params.interface';
import { IResponseCall } from '../../interfaces/common/response-call.interface';
import { DataSourceResponse } from '../../interfaces/data/datasourceresponse.interface';
import { ILiveAgentActivity } from '../../interfaces/live-agent/live-agent-activity';
import { ILiveAgentActivityAggregates } from '../../interfaces/live-agent/live-agent-activity-aggregates';
import { ILiveAgentActivityLog } from '../../interfaces/live-agent/live-agent-activity-log';
import { ILiveAgentGlobalSettings } from '../../interfaces/live-agent/live-agent-global-settings';
import { ILiveAgentInteraction } from '../../interfaces/live-agent/live-agent-interaction';
import { ILiveAgentPriceList } from '../../interfaces/live-agent/live-agent-price-list';
import { ILiveAgentQueue } from '../../interfaces/live-agent/live-agent-queue';
import { ILiveAgentStatus } from '../../interfaces/live-agent/live-agent-status';
import { ILiveAgentStatusAggregates } from '../../interfaces/live-agent/live-agent-status-aggregates';
import { ILiveAgentTask } from '../../interfaces/live-agent/live-agent-task';
import { ILiveAgentTaskAggregates } from '../../interfaces/live-agent/live-agent-task-aggregates';
import { IConversationItem } from '../../interfaces/smart-dialog/conversation-item.interface';

@Injectable()


export class LiveAgentStatitsticsService {

  private _baseUrl: string = `${ApiBaseUrl}liveagentstatistics`;

  constructor(private httpClient: HttpClient) { }

  //-----live agent task-----
  getLiveAgentTasks(request: IActivityRequest): Observable<DataSourceResponse<ILiveAgentTask>> {
    return this.httpClient.post<DataSourceResponse<ILiveAgentTask>>(`${this._baseUrl}/active-tasks`, request);
  }

  getLiveAgentTasksAggregates(request: IActivityRequest): Observable<DataSourceResponse<ILiveAgentTask>> {
    return this.httpClient.post<DataSourceResponse<ILiveAgentTask>>(`${this._baseUrl}/tasks-aggregates`, request);
  }

  getLiveAgentArchivedTasks(request: IActivityRequest): Observable<DataSourceResponse<ILiveAgentTask>> {
    return this.httpClient.post<DataSourceResponse<ILiveAgentTask>>(`${this._baseUrl}/archived-tasks`, request);
  }


  getLiveAgentTasksHistoryAggregates(request: IActivityRequest): Observable<DataSourceResponse<ILiveAgentTask>> {
    return this.httpClient.post<DataSourceResponse<ILiveAgentTask>>(`${this._baseUrl}/taskshistory-aggregates`, request);
  }


  //-----live agent activity-----

  getLiveAgentActivity(request: IActivityRequest): Observable<DataSourceResponse<ILiveAgentActivity>> {
    return this.httpClient.post<DataSourceResponse<ILiveAgentActivity>>(`${this._baseUrl}/activity`, request);
  }

  getLiveAgentActivityAggregates(request: IActivityRequest): Observable<ILiveAgentActivityAggregates> {
    return this.httpClient.post<ILiveAgentActivityAggregates>(`${this._baseUrl}/activity-aggregates`, request);
  }

  getLiveAgentActivityLogs(request: IActivityRequest): Observable<DataSourceResponse<ILiveAgentActivityLog>> {
    return this.httpClient.post<DataSourceResponse<ILiveAgentActivityLog>>(`${this._baseUrl}/activity-log`, request);
  }

  getChatLiveAgentActivity(request: IActivityRequest): Observable<DataSourceResponse<ILiveAgentActivity>> {
    return this.httpClient.post<DataSourceResponse<ILiveAgentActivity>>(`${this._baseUrl}/chat-live-agent-activity`, request);
  }

  getChatLiveAgentActivityAggregates(request: IActivityRequest): Observable<DataSourceResponse<ILiveAgentActivityAggregates>> {
    return this.httpClient.post<DataSourceResponse<ILiveAgentActivityAggregates>>(`${this._baseUrl}/chat-live-agent-activity-aggregates`, request);
  }


  //-----live agent sessions-----
  getSessionsHistory(request: IActivityRequest): Observable<DataSourceResponse<ILiveAgentInteraction>> {
    return this.httpClient.post<DataSourceResponse<ILiveAgentInteraction>>(`${this._baseUrl}/archived-interaction`, request);
  }

  getSessionsHistoryAggregates(request: IActivityRequest): Observable<DataSourceResponse<ILiveAgentInteraction>> {
    return this.httpClient.post<DataSourceResponse<ILiveAgentInteraction>>(`${this._baseUrl}/interaction-aggregates`, request);
  }

  getActiveSessions(request: IActivityRequest): Observable<DataSourceResponse<ILiveAgentInteraction>> {
    return this.httpClient.post<DataSourceResponse<ILiveAgentInteraction>>(`${this._baseUrl}/ongoing-interaction`, request);
  }

  exportArchivedSessions(request: IExportFileRequestParams): Observable<IResponseCall> {
    return this.httpClient.post<IResponseCall>(`${this._baseUrl}/interaction-export`, request);
  }

  getArchivedConversationsItems(conversation: ILiveAgentInteraction, transfered:boolean): Observable<ILiveAgentInteraction> {
    return this.httpClient.post<ILiveAgentInteraction>(`${this._baseUrl}/dialog-archived/${transfered}`, conversation);
  }

  getConversationsItems(conversation: ILiveAgentInteraction): Observable<ILiveAgentInteraction> {
    return this.httpClient.post<ILiveAgentInteraction>(`${this._baseUrl}/dialog`, conversation);
  }

  hasActiveInteractions(type=""): Observable<boolean> {
    return this.httpClient.get<boolean>(`${this._baseUrl}/has-active-sessions/${type}`);
  }


  //-----live agent dashboard statistics-----

  getLiveAgentsTaskAggregates(request: IActivityRequest): Observable<ILiveAgentTaskAggregates> {
    return this.httpClient.post<ILiveAgentTaskAggregates>(`${this._baseUrl}/live-agents-task-aggregates`, request);
  }

  getLiveAgentsStatusAggregates(request: IActivityRequest): Observable<ILiveAgentStatusAggregates> {
    return this.httpClient.post<ILiveAgentStatusAggregates>(`${this._baseUrl}/live-agents-status-aggregates`, request);
  }

  getLiveAgentsStatus(request: IActivityRequest): Observable<DataSourceResponse<ILiveAgentStatus>> {
    return this.httpClient.post<DataSourceResponse<ILiveAgentStatus>>(`${this._baseUrl}/live-agents-status`, request);
  }
}
